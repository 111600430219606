import cx from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { Tooltip } from 'react-tooltip';

import { getInstance } from 'api/index.ts';

import './style.css';


const MINUTES_90 = 60 * 90;
const MINUTES_60 = 60 * 60;
const MINUTES_30 = 60 * 30;
const MINUTES_10 = 60 * 10;


const lookaheadToColor = (lookaheadSeconds: number) => {
  if (lookaheadSeconds >= MINUTES_90) {
    return 'green';
  } else if (lookaheadSeconds >= MINUTES_60) {
    return 'greenyellow';
  } else if (lookaheadSeconds >= MINUTES_30) {
    return 'yellow';
  } else if (lookaheadSeconds >= MINUTES_10) {
    return 'red';
  } else {
    return 'black';
  }
}

const average = (numbers: any) => {
  return numbers.reduce((soFar: number, nextUp: number) => soFar + nextUp, 0) / numbers.length;
}

const forecastLookaheadToHourly = (rawData: any, region: string) => {
  const groupedByHour: any = {};

  rawData.forEach((d: any) => {
    const datetime = new Date(d.datetime);
    const lookahead = d.data[region].forecast_lookahead_seconds;
    datetime.setMinutes(0, 0, 0);
    if (groupedByHour[datetime.toISOString()]) {
      groupedByHour[datetime.toISOString()].push(lookahead);
    } else {
      groupedByHour[datetime.toISOString()] = [lookahead]
    }
  });

  return Object.entries(groupedByHour).map(([hourString, forecastSeconds]): any => {
    const averageForecast = average(forecastSeconds);
    return {
      datetime: new Date(hourString),
      forecastSeconds: averageForecast,
      color: lookaheadToColor(averageForecast),
    };
  })
}

const makeOptions = (points: any, yLabel: string = '') => {
  const series = {
    data: points,
    type: 'line',
    marker: {
      enabled: false,
    },
    margin: 0,
    spacing: [0, 0, 0, 0],
    height: 140,
    states: {
      select: {
        lineWidth: 2,
        lineWidthPlus: 0,
      }
    }
  };

  const minY = points.reduce((soFar: number, nextUp: any) => soFar > nextUp.y ? nextUp.y : soFar, 10000);
  const maxY = points.reduce((soFar: number, nextUp: any) => soFar < nextUp.y ? nextUp.y : soFar, -10000);

  const options: any = {
    plotOptions: {
      line: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            }
          }
        }
      },
      series: {
        enableMouseTracking: false
      }
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      tickLength: 10,
      labels: {
        y: 25,
        formatter: ({ value }: any) => new Date(value).toLocaleTimeString(window.navigator.language, { minute: '2-digit', hour: '2-digit' }),
      },
      tickInterval: 3600 * 6000,
    },
    yAxis: {
      // visible: false,
      startOnTick: false,
      min: minY * 0.99,
      max: maxY,
      title: {
        text: yLabel,
      },
    },
    title: {
      text: null,
    },
    chart: {
      style: {
        fontFamily: 'Poppins',
      },
      backgroundColor: 'transparent',
      type: 'line',
      height: 120,
    },
    credits: {
      enabled: false,
    },
    series,
  };

  return options;
}

const ViewOnlyLineChart = ({
  title,
  data,
  yLabel = '',
}: any) => (
  <div>
    <h4>{title}</h4>
    <HighchartsReact highcharts={Highcharts} options={makeOptions(data.sort((a: any, b: any) => a.x - b.x), yLabel)} />
  </div>
);

const ForecastLookahead = ({
  region,
  rawData,
}: any) => {

  const hourlyData = forecastLookaheadToHourly(rawData, region);

  const regionData = hourlyData.map(({ datetime, color, forecastSeconds }: any) => {
    const datestring = datetime.toLocaleString(window.navigator.language, { month: 'long', day: 'numeric', hour: 'numeric'})
    return <div key={datestring} className={cx("forecast-lookahead--block", color)}>
      <Tooltip
        place="top"
        render={() => (
          <div>
            <strong>{datestring}</strong>
            <div>Forecasts were {Math.round(forecastSeconds/60)} minutes ahead</div>
          </div>
        )}
      />
    </div>
  }).reverse();

  return (
    <div>
      <h4>Forecast lookahead</h4>
      <div><small>How far ahead are the forecasts</small></div>
      {regionData}
    </div>
  );
}



interface ISettingsProps {
}

interface ISettingsState {
  isLoading: boolean;
  data: any;
}


class Status extends React.Component<ISettingsProps, ISettingsState> {
  constructor(props: ISettingsProps) {
    super(props);

    this.state = {
      isLoading: true,
      data: {},
    }
  }

  componentDidMount() {
    getInstance().get('/api/v1/status').then(({ data }) => {
      this.setState({
        isLoading: false,
        data,
      });
    });
  }

  render() {
    const {
      isLoading,
      data,
    } = this.state;

    return (
      <div className="carbonara-status--container">
        <h1>{window.location.hostname.includes("carbonara") ? "Carbonara" : "Grid Carbon"} Status</h1>
        <div>
          <h2><a href="#system-metrics" id="system-metrics">System Metrics</a></h2>
          <small>This is a snapshot in time of the processing backlog. Spikes are normal. Mountain bluffs are not.</small>
          <div>
            <ViewOnlyLineChart title="Forecast backlog" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.system[1].backlog, x: new Date(d.datetime).valueOf() }))} />
            <ViewOnlyLineChart title="Carbon calculation backlog" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.system[0].backlog, x: new Date(d.datetime).valueOf() }))} />
            <ViewOnlyLineChart title="Insert into database backlog" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.system[2].backlog, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#isone" id="isone">ISONE</a></h2>
          <div>
            <ForecastLookahead region="ISONE" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.ISONE.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#nyiso" id="nyiso">NYISO</a></h2>
          <div>
            <ForecastLookahead region="NYISO" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.NYISO.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#pjm" id="pjm">PJM</a></h2>
          <div>
            <ForecastLookahead region="PJM" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.PJM.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#caiso" id="caiso">CAISO</a></h2>
          <div>
            <ForecastLookahead region="CAISO" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.CAISO.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#miso" id="miso">MISO</a></h2>
          <div>
            <ForecastLookahead region="MISO" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.MISO.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#spp" id="spp">SPP</a></h2>
          <div>
            <ForecastLookahead region="SPP" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.SPP.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#bpa" id="bpa">BPA</a></h2>
          <div>
            <ForecastLookahead region="BPA" rawData={isLoading ? [] : data} />
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.BPA.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#ercot" id="ercot">ERCOT</a></h2>
          <div>
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.ERCOT.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#hqt" id="hqt">HQT</a></h2>
          <div>
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.HQT.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
        <div>
          <h2><a href="#ieso" id="ieso">IESO</a></h2>
          <div>
            <ViewOnlyLineChart title="Data latency" yLabel="seconds" data={isLoading ? [] : data.map((d: any) => ({ y: d.data.IESO.scrape_time, x: new Date(d.datetime).valueOf() }))} />
          </div>
        </div>
      </div>
    )
  }
}

export default Status;