import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import { isThinDevice } from 'utils/userAgent.ts';
import { currentCustomer, defaultRegion, hasAnyOnboardingTasksLeft } from 'modules/user/selectors.ts';
import { receiveCurrentUser } from 'modules/user/actions.ts';
import { getInstance } from 'api/index.ts';
import Dropdown from 'components/shared/dropdown.tsx';
import LoadingDots from 'components/shared/loadingdots.tsx';
import {
  receiveRegionHomeReport,
  receiveRegionCarbonTrend,
  receiveRegionHourlyCarbonTrend,
  receiveRegionFuelmixTrend,
  receiveRegionHourlyFuelmixTrend,
} from 'modules/reports/actions.ts';
import {
  fuelmixTrendForRegionFetched,
  hourlyFuelmixTrendForRegionFetched,
  fuelmixTrendData,
  hourlyFuelmixTrendData,
  homeReportForRegionFetched,
  homeReportData,
  carbonTrendForRegionFetched,
  hourlyCarbonTrendForRegionFetched,
  carbonTrendData,
  hourlyCarbonTrendData,
  homeReportLastFetched,
} from 'modules/reports/selectors.ts';

import './style.css';
import {
  CurrentGenerationChart,
  CurrentCarbonIntensityChart,
  FuelmixTrend,
  CarbonIntensityTrend,
  CurrentFuelMix,
} from './charts/index.ts';
import OnboardingTasks from './onboarding.tsx';
import { IOption } from 'components/shared/dropdown';

interface IRegion extends IOption {
  hasSubregions?: boolean,
  baseRegion?: string,
  exclude?: boolean
}

const Regions: {[region: string]: IRegion} = {
  ISONE: {
    label: 'New England (ISONE)',
    value: 'ISONE',
  },
  NYISO: {
    label: 'New York (NYISO)',
    value: 'NYISO',
    hasSubregions: true
  },
  'NYISO.NYCW': {
    label: 'NYC / Westchester (NYISO.NYCW)',
    value: 'NYISO.NYCW',
    baseRegion: 'NYISO'
  },
  'NYISO.NYLI': {
    label: 'Long Island (NYISO.NYLI)',
    value: 'NYISO.NYLI',
    baseRegion: 'NYISO'
  },
  'NYISO.NYUP': {
    label: 'Upstate NY (NYISO.NYUP)',
    value: 'NYISO.NYUP',
    baseRegion: 'NYISO'
  },
  CAISO: {
    label: 'California (CAISO)',
    value: 'CAISO',
  },
  MISO: {
    label: 'Mid-Continent (MISO)',
    value: 'MISO',
  },
  PJM: {
    label: 'Mid-Atlantic (PJM)',
    value: 'PJM',
  },
  IESO: {
    label: 'Ontario (IESO)',
    value: 'IESO',
    exclude: true,
  },
  SPP: {
    label: 'Southwest USA (SPP)',
    value: 'SPP',
  },
  BPA: {
    label: 'Washington, USA (BPA)',
    value: 'BPA',
  },
  ERCOT: {
    label: 'Texas, USA (ERCOT)',
    value: 'ERCOT',
    exclude: true,
  }
}

const Home = ({
}) => {
  const customer: {status: string, id: string} = useSelector(currentCustomer)
  const userDefaultRegion: string = useSelector(defaultRegion)
  const hasOnboardingLeft: boolean = useSelector(hasAnyOnboardingTasksLeft);
  const [selectedRegion, setSelectedRegion] = React.useState(Regions[userDefaultRegion] || Regions.ISONE)
  const chooseRegion = (regionOption: IOption) => {
    setSelectedRegion(Regions[regionOption.value]);
  }

  const [apiData, setApiData] = React.useState({});
  const [carbonData, setCarbonData] = React.useState({});
  const [hourlyCarbonData, setHourlyCarbonData] = React.useState({});
  const [fuelmixData, setFuelmixData] = React.useState({});
  const [hourlyFuelmixData, setHourlyFuelmixData] = React.useState({});
  const dataLoaded = useSelector((state) => homeReportForRegionFetched(state, selectedRegion.value));
  const carbonDataLoaded = useSelector((state) => carbonTrendForRegionFetched(state, selectedRegion.value));
  const hourlyCarbonDataLoaded = useSelector((state) => hourlyCarbonTrendForRegionFetched(state, selectedRegion.value));
  const fuelmixDataLoaded = useSelector((state) => fuelmixTrendForRegionFetched(state, selectedRegion.value));
  const hourlyFuelmixDataLoaded = useSelector((state) => hourlyFuelmixTrendForRegionFetched(state, selectedRegion.value));
  const savedData = useSelector((state) => homeReportData(state, selectedRegion.value));
  const savedCarbonData = useSelector((state) => carbonTrendData(state, selectedRegion.value));
  const savedHourlyCarbonData = useSelector((state) => hourlyCarbonTrendData(state, selectedRegion.value));
  const savedFuelmixData = useSelector((state) => fuelmixTrendData(state, selectedRegion.value));
  const savedHourlyFuelmixData = useSelector((state) => hourlyFuelmixTrendData(state, selectedRegion.value));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true)
  const [isCarbonLoading, setIsCarbonLoading] = React.useState(true)
  const [isHourlyCarbonLoading, setIsHourlyCarbonLoading] = React.useState(true)
  const [isFuelmixTrendLoading, setIsFuelmixTrendLoading] = React.useState(true)
  const [isHourlyFuelmixTrendLoading, setIsHourlyFuelmixTrendLoading] = React.useState(true)
  const [defaultRegionPending, setDefaultRegionPending] = React.useState(false)
  const [defaultRegionSuccess, setDefaultRegionSuccess] = React.useState(false)
  const lastFetched = useSelector((state) => homeReportLastFetched(state, selectedRegion.value));
  React.useEffect(() => {
    const API = getInstance();
    if (!carbonDataLoaded) {
      setIsCarbonLoading(true);
      API
        .get(`/api/v1/reports/${selectedRegion.value}/carbon-trend`)
        .then(({ data }: any) => {
          //data.ci['forecast.ci'] = [];
          //data.forecasted_intensity = [];
          dispatch(receiveRegionCarbonTrend(selectedRegion.value, data));
          setCarbonData(data);
          setIsCarbonLoading(false);
        })
    } else {
      setIsCarbonLoading(false);
      setCarbonData(savedCarbonData);
    }

    if (!hourlyCarbonDataLoaded) {
      setIsHourlyCarbonLoading(true);
      API
        .get(`/api/v1/reports/${selectedRegion.value}/hourly-carbon-trend`)
        .then(({ data }: any) => {
          dispatch(receiveRegionHourlyCarbonTrend(selectedRegion.value, data));
          setHourlyCarbonData(data);
          setIsHourlyCarbonLoading(false);
        })
    } else {
      setIsHourlyCarbonLoading(false);
      setHourlyCarbonData(savedHourlyCarbonData);
    }

    if (!dataLoaded) {
      setIsLoading(true);
      API
        .get(`/api/v1/reports/${selectedRegion.value}`)
        .then(({ data }: any) => {
          dispatch(receiveRegionHomeReport(selectedRegion.value, data));
          setApiData(data);
          setIsLoading(false);
        })
    } else {
      setIsLoading(false);
      setApiData(savedData);
    }

    if (!fuelmixDataLoaded) {
      setIsFuelmixTrendLoading(true);
      API
        .get(`/api/v1/reports/${selectedRegion.value}/fuelmix-trend`)
        .then(({ data }: any) => {
          //data['forecast.gfm'] = [];
          dispatch(receiveRegionFuelmixTrend(selectedRegion.value, data));
          setFuelmixData(data);
          setIsFuelmixTrendLoading(false);
        })
    } else {
      setIsFuelmixTrendLoading(false);
      setFuelmixData(savedFuelmixData);
    }

    if (!hourlyFuelmixDataLoaded) {
      setIsHourlyFuelmixTrendLoading(true);
      API
        .get(`/api/v1/reports/${selectedRegion.value}/hourly-fuelmix-trend`)
        .then(({ data }: any) => {
          dispatch(receiveRegionHourlyFuelmixTrend(selectedRegion.value, data));
          setHourlyFuelmixData(data);
          setIsHourlyFuelmixTrendLoading(false);
        })
    } else {
      setIsHourlyFuelmixTrendLoading(false);
      setHourlyFuelmixData(savedHourlyFuelmixData);
    }
  }, [selectedRegion])

  const setDefaultRegion = () => {
    const API = getInstance();
    setDefaultRegionPending(true);
    API
      .patch('/api/v1/users/me/default-region', { region: selectedRegion.value })
      .then(({ data }: any) => {
        setDefaultRegionSuccess(true);
        setTimeout(() => {
          setDefaultRegionPending(false);
          dispatch(receiveCurrentUser(data.user));
          setDefaultRegionSuccess(false);
        }, 1000);
      })
      .catch(() => {
        setDefaultRegionPending(false);
      })
  }

  const lastFetchedDate = new Date(lastFetched);
  let lastFetchedString = '';
  if (lastFetched !== 0) {
    lastFetchedString += lastFetchedDate.toLocaleDateString(window.navigator.language, { month: 'short', day: 'numeric', year: 'numeric'});
    lastFetchedString += ' at ';
    lastFetchedString += lastFetchedDate.toLocaleTimeString(window.navigator.language, { hour: 'numeric', minute: '2-digit' })
  }

  const baseRegion = Regions[selectedRegion.baseRegion || selectedRegion.value];
  const noSubregionOption: IRegion = {label: 'None', value: baseRegion.value, hasSubregions: true};
  const subregion = selectedRegion == baseRegion ? noSubregionOption : selectedRegion;
  const dropDownView = isThinDevice() ?
    <span className="selected-region--name">
      {baseRegion.label} <FontAwesomeIcon icon={faAngleDown} />
    </span>
    : <h5>
        Current stats for <span className="selected-region--name">
                            {baseRegion.label} <FontAwesomeIcon icon={faAngleDown} />
                          </span>
      </h5>
  const subregionDropDownView = isThinDevice() ?
    <span className="selected-region--name">
      {subregion.label} <FontAwesomeIcon icon={faAngleDown} />
    </span>
    : <h5> , Subregion: <span className="selected-region--name">
                          {subregion.label} <FontAwesomeIcon icon={faAngleDown} />
                        </span>
      </h5>

  const hasSubregions = selectedRegion.hasSubregions || selectedRegion.baseRegion != undefined;
  return (
    <div className="carbonara-home--container">
      {hasOnboardingLeft && !isThinDevice() && <OnboardingTasks />}
      <div className="carbonara-home--content">
        <div className="selected-region--container">
          <div className="select-region-with-default" style={isThinDevice() ? {display: 'grid'} : {}}>
            <Dropdown
              uniqueId='baseRegion'
              options={Object.values(Regions).filter((r: IRegion) => !r.value.includes('.') && !r.exclude)}
              viewText={dropDownView}
              onSelect={chooseRegion}
            />
            {hasSubregions && <Dropdown
              uniqueId='subregion'
              options={[noSubregionOption].concat(Object.values(Regions).filter((r: IRegion) => r.value.includes('.')))}
              viewText={subregionDropDownView}
              onSelect={chooseRegion}
            />}
            {userDefaultRegion !== selectedRegion.value && !isThinDevice() &&
              <div className="carbonara-home-default-region" onClick={setDefaultRegion}>
                Set as default region{defaultRegionPending && <LoadingDots isStatic={defaultRegionSuccess} />}{defaultRegionSuccess && <span className="set-default-region--success">Success!</span>}
              </div>
            }
          </div>
          {!isLoading && !isThinDevice() && <div className="last-updated">Last updated on <strong>{lastFetchedString}</strong></div>}
        </div>
        <CurrentGenerationChart loading={isLoading} value={apiData} trendValue={fuelmixData} />
        <CurrentCarbonIntensityChart loading={isLoading} value={apiData} trendValue={carbonData} />
        <CurrentFuelMix loading={isLoading} value={apiData} viewingEmissions={false} />
        <CurrentFuelMix loading={isLoading} value={apiData} viewingEmissions={true} />
        <FuelmixTrend loading={isFuelmixTrendLoading} value={fuelmixData} />
        <CarbonIntensityTrend loading={isCarbonLoading} value={carbonData} region={selectedRegion.value} />
        {hourlyFuelmixDataLoaded && <FuelmixTrend loading={isHourlyFuelmixTrendLoading} value={hourlyFuelmixData} hourly={true} />}
        {hourlyCarbonDataLoaded && <CarbonIntensityTrend loading={isHourlyCarbonLoading} value={hourlyCarbonData} region={selectedRegion.value} hourly={true} />}
      </div>
    </div>
  )
}


export default Home