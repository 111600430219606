import cx from 'classnames';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getInstance } from 'api/index.ts';
import Button from 'components/shared/button.tsx';
import LoadingDots from 'components/shared/loadingdots.tsx';
import { receiveAPIUsage } from 'modules/user/actions.ts';
import { ICustomer } from 'modules/user/constants';
import { apiKeyValue, apiUsage, currentCustomer } from 'modules/user/selectors.ts';

import RegionSelect from 'components/auth/signup/region-select';
import './style.css';


const formatNum = (num: number) => {
  return Intl.NumberFormat().format(num);
}

const createPlotOptions = (usedCalls: any, allowedCalls: any) => {
  const series = [
    {
      name: 'used',
      y: usedCalls,
      color: 'var(--color-blue-1)',
      dataLabels: { enabled: false },
    },
    {
      name: 'remaining',
      y: allowedCalls - usedCalls,
      color: 'var(--color-grey-3)',
      dataLabels: { enabled: false },
    },
  ];

  return {
    chart: {
      style: {
        fontFamily: 'Poppins',
      },
      backgroundColor: 'transparent',
      type: 'pie',
      margin: 0,
      spacing: [0, 0, 0, 0],
      height: 140,
    },
    plotOptions: {
      pie: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        }
      },
    },
    tooltip: {
      enabled: false,
    },
    title: '',
    series: [{
      type: 'pie',
      data: series,
      size: '80%',
      innerSize: '60%',
    }],
    credits: {
      enabled: false,
    },
  }
}




interface ISettingsProps {
  api_key_value: string;
  usage: any;
  customer: ICustomer;
  history: any;
  receiveUsage: (data: any) => void;
}

interface ISettingsState {
  isLoading: boolean;
  copied: boolean;
}


class Settings extends React.Component<ISettingsProps, ISettingsState> {
  private textArea: any;

  constructor(props: ISettingsProps) {
    super(props);

    this.state = {
      isLoading: !props.usage,
      copied: false,
    }
  }

  componentDidMount() {
    const { customer } = this.props;

    if (!this.props.usage && customer.status === 'active') {
      getInstance().get('/api/v1/customers/usage').then(({ data }) => {
        const lastDatum: any = data.usage
        const [
          usedCalls,
          callsLeft
        ] = lastDatum;
        this.props.receiveUsage({ usedCalls, allowedCalls: callsLeft + usedCalls });
        this.setState({
          ...this.state,
          isLoading: false,
        });
      });
    }
  }

  copySnippet = () => {
    navigator.clipboard.writeText(this.textArea.value);
    this.setState({
      ...this.state,
      copied: true,
    }, this.unsetCopied)
  }

  unsetCopied = () => {
    setTimeout(() => this.setState({ ...this.state, copied: false }), 2000);
  }

  render() {
    const {
      isLoading,
      copied,
    } = this.state;

    const {
      api_key_value,
      usage,
      customer,
      history
    } = this.props;

    const usedPct = !isLoading && Math.ceil(usage.usedCalls / usage.allowedCalls * 100);

    const hasWebhooks = customer.attributes?.find(x => x.name === "has_webhooks")?.value === true;

    const email = window.location.hostname.split(".")[0] + "@singularity.energy";

    if (customer.status !== 'active') {
      return (
        <div className="carbonara-jumbotron-activate-account">
          <h2>Thanks for signing up!</h2>
          <div>
            To get your API key, <strong>please verify your account by clicking the button in the email we just sent you</strong>.
            If you need help, please contact us at <a href={`mailto:${email}`}>{email}</a>.
          </div>
        </div>
      );
    }

    const apiKeyExpiration = new Date(customer.api_key_expiration);
    const formattedExpiration = apiKeyExpiration.toLocaleDateString(window.navigator.language, { month: 'short', day: 'numeric', year: 'numeric'});
    const minutesToExpiration = (apiKeyExpiration.getTime() - Date.now()) / (1000 * 60);
    const hoursToExpiration = minutesToExpiration / 60;
    const daysToExpiration = hoursToExpiration / 24;
    const expirationText = daysToExpiration >= 2   ? "will expire in " + Math.round(daysToExpiration) + " days on " + formattedExpiration :
                           hoursToExpiration > 1   ? "will expire in " + Math.round(hoursToExpiration) + " hours" :
                           minutesToExpiration > 1 ? "will expire in " + Math.round(minutesToExpiration) + " minutes" :
                                                     "expired on " + formattedExpiration;
    
    const apiRegions = customer.attributes?.find(x => x.name === "api_regions")?.value as string[];
    const regionsText = apiRegions && (apiRegions.includes("*") ? "All Regions" : apiRegions.join(", "));

    if (apiRegions && apiRegions.length == 0) {
      return <RegionSelect canSkip={false}/>;
    }

    return (
      <div className="api-settings--container">
        <div className="api-settings-section--container">
          <h5>API Key</h5>
          <div>
            <small>Read our API documentation <a href="https://docs.singularity.energy" target="_blank">here</a>.</small>
          </div>
          <div className="api-key-copy--prompt">Click to copy</div>
          <pre className="api-key--value" onClick={this.copySnippet}>{api_key_value}</pre>
          <div className={cx('api-key-copied--text', { visible: copied })}>Copied to clipboard!</div>
          <textarea className="api-key-textarea" readOnly={true} ref={elt => this.textArea = elt} value={api_key_value} />
          {customer.api_key_expiration && <div>
            <div><small>Your <b>{customer.api_key_plan_name || "API Subscription"}</b> {expirationText}.</small></div>
            <div><small>If you would like to {daysToExpiration > 0 ? "extend" : "renew"} your subscription, <a href={`mailto:${email}`}>send us an email</a>.</small></div>
          </div>}
        </div>
        <div className="api-settings-section--container fixed-width">
          <h5>Usage</h5>
          {isLoading && <div className="api-settings--loading">Loading<LoadingDots /></div>}
          {!isLoading && <small>You used {formatNum(usage.usedCalls)} {usage.allowedCalls !== 0 && `out of ${formatNum(usage.allowedCalls)}`} API calls this month</small>}
          <div className="api-settings-usage-count--container">
            {!isLoading && !Number.isNaN(usedPct) && <h4 className="api-usage-percent">{usedPct}%</h4>}
            {!isLoading && !Number.isNaN(usedPct) && <HighchartsReact highcharts={Highcharts} options={createPlotOptions(usage.usedCalls, usage.allowedCalls)} />}
          </div>
        </div>
        {apiRegions && <div className="api-settings-section--container fixed-width">
          <h5>API Access</h5>
          <div>
          <small>
            For more details, see our <a href="https://docs.singularity.energy/docs/singularity-api/coverage-and-availability" target="_blank">Coverage and Availability documentation</a>.
            If you would like to change your access, <a href={`mailto:${email}`}>send us an email</a>.
          </small>
          </div>
          <br/>
          <div>Regions: <b>{regionsText}</b></div>
        </div>}
        {hasWebhooks && <div className="api-settings-section--container fixed-width flex">
          <h5>Webhooks</h5>
          <Button buttonType="button" size="large" onClick={() => history.push("/webhooks")}className="api-settings-section--large-button">Open Dashboard</Button>
        </div>}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  api_key_value: apiKeyValue(state),
  usage: apiUsage(state),
  customer: currentCustomer(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  receiveUsage: (data: any) => dispatch(receiveAPIUsage(data)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings as any))
