const COOKIE_NAME = window.location.hostname.includes('carbonara') ? 'CARBONARA_AUTH' : 'GRID_CARBON_AUTH';


export const expireToken = () => {
  document.cookie = `${COOKIE_NAME}=;expires=${(new Date()).toUTCString()};path=/;SameSite=Lax;domain=${document.domain}`;
  try {
    return localStorage.removeItem(COOKIE_NAME);
  } catch (err) {
    return null;
  }
};

export const getToken = () => {
  const startIndex = document.cookie.indexOf(COOKIE_NAME);
  if (startIndex === -1) {
    return getCookieFromLocalStorage();
  }
  const startSlice = startIndex + COOKIE_NAME.length + 1;
  const endIndex = document.cookie.slice(startIndex).indexOf(';');
  if (endIndex === -1) {
    return document.cookie.slice(startSlice);
  } else {
    return document.cookie.slice(startSlice, startIndex + endIndex);
  }
};

const getCookieFromLocalStorage = () => {
  try {
    return localStorage.getItem(COOKIE_NAME);
  } catch (err) {
    return null;
  }
};

export const setToken = (token: string) => {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 7);

  const cookie = `${COOKIE_NAME}=${token};expires=${expireDate.toUTCString()};path=/;domain=${document.domain}`;
  document.cookie = cookie;
  try {
    localStorage.setItem(COOKIE_NAME, token);
  } catch (err) {
    console.info('could not store token to localStorage');
  }
};


const systemAdminRoles = new Set([
    'sadmin',
    'uadmin',
])

const roleValues: any = {
    'visitor': 0,
    'contributor': 1,
    'user': 2,
    'billing': 3,
    'admin': 4,
    'owner': 5,
    'sadmin': 6,
    'uadmin': 7,
}


export const isSystemAdmin = (role: string) => systemAdminRoles.has(role)
export const isAdmin = (role: string) => {
  const roleValue: number = roleValues[role] || 0;
  return roleValue >= roleValues['admin'];
}
