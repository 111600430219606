import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/pro-regular-svg-icons';


import { getInstance } from 'api/index.ts';
import { setToken } from 'utils/auth.ts';
import { currentUser } from 'modules/user/selectors.ts';
import { DEBUG } from 'constants/resources.ts';
import Button from 'components/shared/button.tsx';

import './style.css';


interface ISignupProps {
  history: any;
  user: any;
}

enum SignupSteps {
  INITIAL,
  CONFIRM
}

interface ISignupState {
  fullName: string;
  company: string;
  password: string;
  email: string;
  roleAtCompany: string;
  usageIntention: string;
  attribution: string;
  attributionOther: string;
  error: any;
  isLoading: boolean;
  marketingOptedIn: boolean;
  step: SignupSteps;
  usingPersonalEmail: boolean;
  wantsToUsePersonalEmail: boolean;
}

const personalEmailDomains: string[] = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'msn.com',
  'yahoo.fr',
  'wanadoo.fr',
  'orange.fr',
  'comcast.net',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'live.com',
  'rediffmail.com',
  'free.fr',
  'gmx.de',
  'web.de',
  'yandex.ru',
  'ymail.com',
  'libero.it',
  'outlook.com',
]


class Signup extends React.Component<ISignupProps, ISignupState> {
  constructor(props: ISignupProps) {
    super(props);

    this.state = {
      fullName: '',
      company: '',
      password: '',
      email: '',
      roleAtCompany: '',
      usageIntention: '',
      attribution: '',
      attributionOther: '',
      error: null,
      isLoading: false,
      marketingOptedIn: false,
      usingPersonalEmail: false,
      wantsToUsePersonalEmail: false,
      step: SignupSteps.INITIAL,
    };
  }

  componentDidMount() {
    this.checkIsAlreadyLoggedIn();
  }

  componentDidUpdate() {
    this.checkIsAlreadyLoggedIn();
  }

  checkIsAlreadyLoggedIn = () => {
    if (this.props.user) {
      this.props.history.push('/')
    }
  }

  checkIsUsingPersonalEmail = () => {
    const emailDomain = this.state.email.split('@')[1];
    if (!emailDomain) return

    if (personalEmailDomains.indexOf(emailDomain) !== -1) {
      this.setState({
        usingPersonalEmail: true,
      });
    } else {
      this.setState({
        usingPersonalEmail: false,
      });
    }
  }

  submit = async (e: any) => {
    e.preventDefault();
    this.setState({
      error: null,
      isLoading: true,
    });

    if (!DEBUG) {
      (window as any).drift.on('ready', (api: any) => {
        api.setUserAttributes({
          opted_into_marketing_comms: this.state.marketingOptedIn,
        });
      })
    }
    const http = getInstance();
    try {
      const payload = {
        name: this.state.fullName,
        company: this.state.company,
        email: this.state.email.toLowerCase(),
        password: this.state.password,
        attribution: (this.state.attribution == "Other") ? `${this.state.attribution}: ${this.state.attributionOther}` : this.state.attribution,
        opted_into_marketing_comms: this.state.marketingOptedIn,
        role_at_company: this.state.roleAtCompany,
        signup_usage_intention: this.state.usageIntention,
      };
      const { data } = await http.post('/api/v1/registrations', payload);
      const token = data.token;
      setToken(token);
      setTimeout(() => (window.location as any) = '/app/home', 0);
    } catch (e) {
      this.setState({
        error: e.error_message || 'Signup failed.',
        isLoading: false,
        step: SignupSteps.INITIAL,
      });
    }
  }

  renderConfirmForm() {
    const {
      company,
      roleAtCompany,
      usageIntention,
      attribution,
      attributionOther,
      isLoading,
      error,
    } = this.state;

    const setCompany = (c: string) => this.setState({ company: c });
    const setRole = (r: string) => this.setState({ roleAtCompany: r });
    const setAttribution = (a: string) => this.setState({ attribution: a });
    const setAttributionOther = (a: string) => this.setState({ attributionOther: a });
    const setUsageIntention = (ui: string) => this.setState({ usageIntention: ui });
    const disabled = !company || !roleAtCompany || !usageIntention;
    const hidden = "hidden"
    const submit = () => !disabled && this.submit(null)

    return (
      <React.Fragment>
        <h3>Almost there!</h3>
        <div>Tell us a little about yourself.</div>
        <form onSubmit={submit}>
          <label>
            Company
            <input value={company} onChange={(e) => setCompany(e.target.value)} />
          </label>
          <label>
            What's your role?
            <input value={roleAtCompany} onChange={(e) => setRole(e.target.value)} />
          </label>
          <label>
            How do you plan on using Carbonara?
            <textarea rows={2} value={usageIntention} onChange={(e) => setUsageIntention(e.target.value)} />
          </label>
          <label>
            How did you hear about Singularity?
            <select className="attestation-select" value={attribution} onChange={(e) => setAttribution(e.target.value)}>
              <option></option>
              <option >Google Search</option>
              <option >Through a friend</option>
              <option >Press / Blog Article</option>
              <option >Current Customer</option>
              <option >Social Media</option>
              <option >Other</option>
            </select>
            {(attribution == "Other") && (<textarea rows={1} value={attributionOther} onChange={(e) => setAttributionOther(e.target.value)} />)}
          </label>
          <Button className="carbonara-signup-button--confirm" disabled={disabled} buttonType="submit" loading={isLoading} onClick={this.submit} type="secondary">Continue to account</Button>
          {error && <div className="carbonara-signup-error">{error}</div>}
        </form>
      </React.Fragment>
    )
  }


  renderInitialForm() {
    const {
      fullName,
      email,
      password,
      marketingOptedIn,
      error,
      usingPersonalEmail,
      wantsToUsePersonalEmail,
    } = this.state;

    const accidentallyUsingPersonalEmail = usingPersonalEmail && !wantsToUsePersonalEmail;
    const disabled = password.length < 8 || !email || !fullName || accidentallyUsingPersonalEmail;

    const setFullName = (f: string) => this.setState({ fullName: f });
    const setEmail = (e: string) => this.setState({ email: e }, this.checkIsUsingPersonalEmail);
    const setPassword = (p: string) => this.setState({ password: p });
    const setMarketingOptedIn = (moi: boolean) => this.setState({ marketingOptedIn: moi });
    const dismissPersonalEmailWarning = () => this.setState({ wantsToUsePersonalEmail: true });
    const moveToConfirm = () => !disabled && this.setState({ step: SignupSteps.CONFIRM });

    return (
      <React.Fragment>
        <h3>Create your free account</h3>
        <form onSubmit={moveToConfirm} className="initial-signup-form">
          <FontAwesomeIcon className="auth-modal-input--icon" icon={faUser} />
          <label htmlFor="full-name">
            Your name
            <input className="with-icon" type="text" id="full-name" placeholder="Jane Doe" value={fullName} onChange={(e) => setFullName(e.target.value)} />
          </label>
          <FontAwesomeIcon className="auth-modal-input--icon" icon={faEnvelope} />
          <label htmlFor="email-address">
            Email address
            <input className="with-icon" value={email} placeholder="you@company.com" id="email-address" type="email" onChange={(e) => setEmail(e.target.value)} />
          </label>
          <div className="accidental-personal-email--warning">
            {accidentallyUsingPersonalEmail && <span>Carbonara works best when you use your work email and invite your team. <span onClick={dismissPersonalEmailWarning} className="personal-email-warning-dismiss">I want to use my this email address.</span></span>}
          </div>
          <FontAwesomeIcon className="auth-modal-input--icon" icon={faLock} />
          <label htmlFor="password">
            Password
            <input className="with-icon" type="password" id="password" placeholder="your password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>
          <div className="small-signup-disclaimer">By signing up you agree to our <a href="/terms-of-service" target="_blank">terms of service</a>, <a href="/privacy-policy" target="_blank">privacy policy</a>, and <a href="/cookie-policy" target="_blank">cookie policy</a></div>
          <label className="receive-marketing-email--input">
            <input type="checkbox" checked={marketingOptedIn} onChange={() => setMarketingOptedIn(!marketingOptedIn)} />
            Keep me up to date on advancements in carbon data tracking
          </label>
          <Button className="carbonara-signup-button" disabled={disabled} buttonType="submit" onClick={moveToConfirm}>Create account</Button>
          {error && <div className="carbonara-signup-error">{error}</div>}
        </form>
      </React.Fragment>
    );

  }

  render() {
    const {
      step,
    } = this.state;

    const {
      history,
    } = this.props;

    return (
      <div className="auth-signup--form">
        <div className="auth-carbonara-branding--container">
          <h2>Carbonara</h2>
          <h5>By <a target="_blank" href="https://www.singularity.energy">Singularity</a></h5>
        </div>
        <div className="auth-form--navigation">
          <div className="auth-form-nav--option" onClick={() => history.push('/login')}>Log in</div>
          <div className="auth-form-nav--option active">Sign up</div>
        </div>
        <div className="auth-signup-form--content">
          {step === SignupSteps.INITIAL && this.renderInitialForm()}
          {step === SignupSteps.CONFIRM && this.renderConfirmForm()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  user: currentUser(state),
});


export default connect(mapStateToProps)(withRouter(Signup as any));