import * as React from 'react';

import LoadingDots from 'components/shared/loadingdots.tsx';


const LoadingChart = () => <div>
  <h4 className="loading-chart--title">Loading Data<LoadingDots /></h4>
</div>



export default LoadingChart