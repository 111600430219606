import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

import {
  exploreDataOnboardingDone,
  viewAPIDocumentationOnboardingDone,
  inviteMoreTeamOnboardingDone,
  currentUserIsAdmin,
} from 'modules/user/selectors.ts';
import { updateOnboarding } from 'modules/user/actions.ts';


const OnboardingTasks = ({
  exploreDataDone,
  viewAPIDocumentationDone,
  inviteMoreTeamDone,
  updateOnboarding,
  isAdmin,
}: any) => {
  const [localExploreDone, setLocalExploreDone] = React.useState(exploreDataDone);
  const [localViewDocsDone, setLocalViewDocsDone] = React.useState(viewAPIDocumentationDone);
  const [localInviteTeamDone, setLocalInviteTeamDone] = React.useState(inviteMoreTeamDone || !isAdmin);
  const [disappear, setDisappear] = React.useState(false);

  React.useEffect(() => {
    const allDone = localInviteTeamDone && localExploreDone && localViewDocsDone;
    let timeout = 0;
    if (allDone) {
      setDisappear(true);
      timeout = 340;
    }
    setTimeout(() => updateOnboarding({
      onboarding_explore_data_done: localExploreDone,
      onboarding_view_api_docs_done: localViewDocsDone,
      onboarding_invite_more_team_done: localInviteTeamDone,
    }), timeout);
  }, [localInviteTeamDone, localViewDocsDone, localExploreDone])

  const toggleExploreData = () => {
    setLocalExploreDone(!exploreDataDone);
  };

  const toggleViewDocs = () => {
    setLocalViewDocsDone(!viewAPIDocumentationDone);
  };

  const toggleInviteTeam = () => {
    setLocalInviteTeamDone(!inviteMoreTeamDone);
  };

  const skipOnboarding = () => {
    setLocalExploreDone(true);
    setLocalInviteTeamDone(true);
    setLocalViewDocsDone(true);
  }

  return (
    <div className={cx("carbonara-home-onboarding--container", { disappear })}>
      <div>Getting started checklist</div>
      <FontAwesomeIcon className="onboarding-close--icon" size="lg" icon={faTimes} onClick={skipOnboarding} />
      <div className="onboarding-checklist--container">
        <div className={cx("onboarding-checklist--item", { completed: localExploreDone })}>
          <div className={cx('onboarding-checklist-item--count', { completed: localExploreDone })}>{localExploreDone ? <FontAwesomeIcon icon={faCheck} /> : '1'}</div>
          <div className="onboarding-checklist-item--content">
            <div>
              <h4>Explore data for your region</h4>
              <div>View the data below to see how carbon intense your region's fuels sources are.</div>
            </div>
            <div className={cx('onboarding-checklist-item--status', { complete: localExploreDone })} onClick={toggleExploreData}>{localExploreDone ? 'Completed' : 'Mark as complete'}</div>
          </div>
        </div>
        <div className={cx("onboarding-checklist--item", { completed: localViewDocsDone })}>
          <div className={cx('onboarding-checklist-item--count', { completed: localViewDocsDone })}>{localViewDocsDone ? <FontAwesomeIcon icon={faCheck} /> : '2'}</div>
          <div className="onboarding-checklist-item--content">
            <div>
              <h4>Use the API</h4>
              <div>Take a look at our API documentation to get a deeper understanding of our data. Take a look <Link to="/settings/api">here</Link>.</div>
            </div>
            <div className={cx('onboarding-checklist-item--status', { complete: localViewDocsDone })} onClick={toggleViewDocs}>{localViewDocsDone ? 'Completed' : 'Mark as complete'}</div>
          </div>
        </div>
        {isAdmin &&
          <div className={cx("onboarding-checklist--item", { completed: localInviteTeamDone })}>
            <div className={cx('onboarding-checklist-item--count', { completed: localInviteTeamDone })}>{localInviteTeamDone ? <FontAwesomeIcon icon={faCheck} /> : '3'}</div>
            <div className="onboarding-checklist-item--content">
              <div>
                <h4>Invite your team</h4>
                <div>Don't keep the insights to yourself, invite your team! You can do that <Link to="/settings/team">here</Link>.</div>
              </div>
              <div className={cx('onboarding-checklist-item--status', { complete: localInviteTeamDone })} onClick={toggleInviteTeam}>{localInviteTeamDone ? 'Completed' : 'Mark as complete'}</div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}


const mapStateToProps = (state: any) => ({
  exploreDataDone: exploreDataOnboardingDone(state),
  viewAPIDocumentationDone: viewAPIDocumentationOnboardingDone(state),
  inviteMoreTeamDone: inviteMoreTeamOnboardingDone(state),
  isAdmin: currentUserIsAdmin(state),
});


const mapDispatchToProps = (dispatch: any) => ({
  updateOnboarding: (onboarding: any) => dispatch(updateOnboarding(onboarding)),
})


export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTasks);