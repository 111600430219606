import * as React from 'react';
import { Tooltip } from 'react-tooltip';

import { getInstance } from "api/index.ts";
import Button from 'components/shared/button';
import MapView from './region-map';

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';

interface IRegionInfo {
  code: string;
  description: string;
  name: string;
}

interface IRegionSelectProps {
  canSkip: boolean;
}

const API = getInstance();

const RegionSelect = ({canSkip}: IRegionSelectProps) => {
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [regionInfo, setRegionInfo] = React.useState<Map<string, IRegionInfo>>(new Map());
  const [regionInfoLoading, setRegionInfoLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const submit = (regions: string[], path: string) => {
    setIsLoading(true);
    API
      .patch("/api/v1/customers/me/update-api-key-expiration", {
        "expiration_date": new Date(new Date().setDate(new Date().getDate() + 60)).toISOString()
      }).then(() => {
        API
          .patch("/api/v1/customers/me/api-regions", {regions: regions})
          .then(() => {
            (window.location as any) = path;
            setIsLoading(false);
          }).catch((err: any) => {
            setError(err.error_message || "Failed to submit regions");
          });
      }).catch((err: any) => {
        setError(err.error_message || "Failed to update API key");
      });
  };

  React.useEffect(() => {
    if (!regionInfoLoading && regionInfo.size === 0) {
      setRegionInfoLoading(true);
      API
        .get("/api/v1/data_proxy/list-regions")
        .then((d: {regions: IRegionInfo[]}) => {
          setRegionInfo(new Map<string, IRegionInfo>(d.regions.map(r => [r.code, r])));
          setRegionInfoLoading(false);
        })
        .catch((err: any) => {
          setError(err.error_message || "Failed to fetch region info");
          setRegionInfoLoading(false);
        });
    }
  }, []);

  const tableRows = selectedRegions.map((region) => (
    <tr key={region}>
      <td>{region}</td>
      <td>{regionInfo.get(region)?.name || "..."}</td>
    </tr>
  ));

  const cost = Math.min(2500, Math.max(1000, selectedRegions.length * 500));

  return (
    <div className='region-select--root'>
      <div className='region-select--sidebar'>
        <h1>Welcome to the Grid Carbon API</h1>
        <p>We'll start you off with a <b>60-day free trial</b> of up to 10 regions.</p>
        <p>
          Take a look at the regions we have available and select which ones you're interested in.
          Don't worry: you can change this selection later, or get in touch with us if you need more than 10 regions (<a href="mailto:support@singularity.energy">support@singularity.energy</a>).
        </p>
        <p>You can always refer to <a href="https://docs.singularity.energy/docs/singularity-api/coverage-and-availability" target="_blank">our documentation</a> in the future for a more detailed breakdown of our data.</p>
        <p>You've selected {selectedRegions.length}/10 regions.</p>
        {selectedRegions.length > 0 && <table>
          <colgroup>
            <col style={{width: "20%"}} />
            <col style={{width: "80%"}} />
          </colgroup>
          <thead>
            <tr>
              <th>Code</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>}
        <p><b>First 60 days: </b>FREE</p>
        <p><b>After free trial: </b>{cost.toLocaleString(window.navigator.language, {style: 'currency', currency: 'USD', maximumFractionDigits: 0})}/month <FontAwesomeIcon className='region-select--sidebar-icon' icon={faQuestionCircle} /></p>
        <Tooltip
          anchorSelect=".region-select--sidebar-icon"
          place="top"
          content="Monthly pricing is $500 per region, with a minimum of $1000 and a maximum of $2500."
          className="region-select--sidebar-tooltip"
        />
        <Button className="carbonara-signup-button--confirm" disabled={selectedRegions.length == 0} loading={isLoading} buttonType="button" onClick={() => submit(selectedRegions, "/app/settings/api")} type="secondary">Continue</Button>
        {error && <div className="carbonara-signup-error">{error}</div>}
        {canSkip && <hr className="region-select--hr"/>}
        {canSkip && <Button className="carbonara-signup-button--confirm" loading={isLoading} buttonType="button" onClick={() => submit([], "/app/home")} type="secondary">I'll do this later</Button>}
      </div>
      <MapView regionSelectCallback={setSelectedRegions}/>
    </div>
  )
}

export default RegionSelect;