import * as React from 'react';


export const MapLegend = () => (
  <div className="eia-map-legend--container">
    <div className="eia-map-legend-color--container">
      <div className="eia-map-legend-colors--container">
        <div style={{ background: '#33c7b0' }} className="eia-map-legend-colors--color" />
        <div style={{ background: '#8edde2' }} className="eia-map-legend-colors--color" />
        <div style={{ background: '#db88d3' }} className="eia-map-legend-colors--color" />
        <div style={{ background: '#ffb476' }} className="eia-map-legend-colors--color" />
        <div style={{ background: '#ff5a5b' }} className="eia-map-legend-colors--color" />
      </div>
      <div className="eia-map-legend-color-labels--container">
        <div>Low carbon intensity</div>
        <div>High carbon intensity</div>
      </div>
    </div>
    <div>
      <div className="eia-map-legend-size--label">Most MW generation</div>
      <svg className="eia-map-legend--svg">
        <circle r={6} cx={50} cy={53} fill="transparent" stroke="black" strokeWidth="1.5" />
        <circle r={22} cx={49} cy={23} fill="transparent" stroke="black" strokeWidth="1.5" />
      </svg>
      <div className="eia-map-legend-size--label">Least MW generation</div>
    </div>
  </div>
)

export default MapLegend;