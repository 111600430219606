import * as React from 'react';


interface ICircleProps {
  cx: number;
  cy: number;
};


interface IStringICPMap {
  [key: string]: ICircleProps;
}

interface IStringNumMap {
  [key: string]: number;
}

interface IStringMap {
  [key: string]: string;
}

export interface IRegionInfo {
  region: string;
  generation: number;
  genCI: number;
  consumedCI: number;
  regionFlow: IStringNumMap;
  carbonFlow: IStringNumMap;
  color: string;
}


export const regionToCircleProps100p: IStringICPMap = {
  "EIA.MISO":{"cx":470,"cy":196},
  "ERCO":{"cx":360,"cy":390},
  "EIA.NYIS":{"cx":700,"cy":150},
  "EIA.ISNE":{"cx":740,"cy":130},
  "EIA.BPAT":{"cx":80,"cy":100},
  "EIA.SWPP":{"cx":370,"cy":280},
  "LGEE":{"cx":570,"cy":270},
  "EIA.PJM":{"cx":650,"cy":230},
  "EIA.CISO":{"cx":60,"cy":270},
  "TVA":{"cx":550,"cy":320},
  "SPA":{"cx":450,"cy":306},
  "SOCO":{"cx":576,"cy":376},
  "AEC":{"cx":536,"cy":406},
  "SCEG":{"cx":640,"cy":356},
  "SC":{"cx":654,"cy":340},
  "SEPA":{"cx":628,"cy":340},
  "DUK":{"cx":634,"cy":316},
  "CPLW":{"cx":612,"cy":310},
  "YAD":{"cx":672,"cy":290},
  "CPLE":{"cx":700,"cy":300},
  "OVEC":{"cx":590,"cy":246},
  "AECI":{"cx":456,"cy":256},
  "GLHB":{"cx":508,"cy":284},
  "TAL":{"cx":576,"cy":406},
  "JEA":{"cx":636,"cy":410},
  "GVL":{"cx":624,"cy":424},
  "FPC":{"cx":636,"cy":430},
  "SEC":{"cx":628,"cy":440},
  "FMPP":{"cx":644,"cy":452},
  "TEC":{"cx":640,"cy":464},
  "FPL":{"cx":660,"cy":470},
  "HST":{"cx":668,"cy":492},
  "NSB":{"cx":652,"cy":428},
  "EPE":{"cx":250,"cy":376},
  "PNM":{"cx":240,"cy":336},
  "PSCO":{"cx":280,"cy":270},
  "WACM":{"cx":270,"cy":230},
  "WAUW":{"cx":256,"cy":84},
  "WWA":{"cx":236,"cy":56},
  "GWA":{"cx":210,"cy":70},
  "NWMT":{"cx":224,"cy":106},
  "IPCO":{"cx":150,"cy":140},
  "PACE":{"cx":204,"cy":202},
  "TEPC":{"cx":176,"cy":370},
  "DEAA":{"cx":136,"cy":340},
  "HGMA":{"cx":124,"cy":332},
  "GRIF":{"cx":128,"cy":304},
  "WALC":{"cx":132,"cy":290},
  "AZPS":{"cx":156,"cy":316},
  "SRP":{"cx":172,"cy":344},
  "IID":{"cx":92,"cy":334},
  "LDWP":{"cx":76,"cy":304},
  "TIDC":{"cx":44,"cy":252},
  "BANC":{"cx":36,"cy":204},
  "NEVP":{"cx":96,"cy":210},
  "PACW":{"cx":56,"cy":130},
  "PGE":{"cx":46,"cy":96},
  "AVRN":{"cx":72,"cy":84},
  "GRID":{"cx":92,"cy":88},
  "TPWR":{"cx":64,"cy":72},
  "SCL":{"cx":62,"cy":44},
  "PSEI":{"cx":80,"cy":56},
  "CHPD":{"cx":90,"cy":40},
  "DOPD":{"cx":100,"cy":48},
  "AVA":{"cx":130,"cy":52},
  "GCPD":{"cx":110,"cy":64},
  "BCHA": {"cx": 112, "cy": 12},
  "CFE": {"cx": 125, "cy": 425},
  "HQT": {"cx": 695, "cy": 82},
  "IESO": {"cx": 525, "cy": 65},
  "NBSO": {"cx": 785, "cy": 35},
  "AESO": {"cx": 225, "cy": 25},
  "CEN": {"cx": 285, "cy": 455},
  "MHEB": {"cx": 385, "cy": 35},
  "default":{"cx":0,"cy":0}
};


export const regionToCircleProps: IStringICPMap = {
  ['EIA.MISO']: {
    cx: 235,
    cy: 98,
  },
  ERCO: {
    cx: 180,
    cy: 195,
  },
  ['EIA.NYIS']: {
    cx: 350,
    cy: 75,
  },
  ['EIA.ISNE']: {
    cx: 370,
    cy: 65,
  },
  ['EIA.BPAT']: {
    cx: 40,
    cy: 50,
  },
  ['EIA.SWPP']: {
    cx: 185,
    cy: 140,
  },
  LGEE: {
    cx: 285,
    cy: 135,
  },
  ['EIA.PJM']: {
    cx: 325,
    cy: 115,
  },
  ['EIA.CISO']: {
    cx: 30,
    cy: 135,
  },
  TVA: {
    cx: 275,
    cy: 160,
  },
  SPA: {
    cx: 225,
    cy: 153,
  },
  SOCO: {
    cx: 288,
    cy: 188,
  },
  AEC: {
    cx: 268,
    cy: 203,
  },
  SCEG: {
    cx: 320,
    cy: 178,
  },
  SC: {
    cx: 327,
    cy: 170,
  },
  SEPA: {
    cx: 314,
    cy: 170,
  },
  DUK: {
    cx: 317,
    cy: 158,
  },
  CPLW: {
    cx: 306,
    cy: 155,
  },
  YAD: {
    cx: 336,
    cy: 145,
  },
  CPLE: {
    cx: 350,
    cy: 150,
  },
  OVEC: {
    cx: 295,
    cy: 123,
  },
  AECI: {
    cx: 228,
    cy: 128,
  },
  GLHB: {
    cx: 254,
    cy: 142,
  },
  TAL: {
    cx: 288,
    cy: 203,
  },
  JEA: {
    cx: 318,
    cy: 205,
  },
  GVL: {
    cx: 312,
    cy: 212,
  },
  FPC: {
    cx: 318,
    cy: 215,
  },
  SEC: {
    cx: 314,
    cy: 220,
  },
  FMPP: {
    cx: 322,
    cy: 226,
  },
  TEC: {
    cx: 320,
    cy: 232,
  },
  FPL: {
    cx: 330,
    cy: 235,
  },
  HST: {
    cx: 334,
    cy: 246,
  },
  NSB: {
    cx: 326,
    cy: 214,
  },
  EPE: {
    cx: 125,
    cy: 188,
  },
  PNM: {
    cx: 120,
    cy: 168,
  },
  PSCO: {
    cx: 140,
    cy: 135,
  },
  WACM: {
    cx: 135,
    cy: 115,
  },
  WAUW: {
    cx: 128,
    cy: 42,
  },
  WWA: {
    cx: 118,
    cy: 28
  },
  GWA: {
    cx: 105,
    cy: 35,
  },
  NWMT: {
    cx: 112,
    cy: 53,
  },
  IPCO: {
    cx: 75,
    cy: 70,
  },
  PACE: {
    cx: 102,
    cy: 101,
  },
  TEPC: {
    cx: 88,
    cy: 185,
  },
  // GRMA: {
  //   cx: 72,
  //   cy: 178,
  // },
  DEAA: {
    cx: 68,
    cy: 170,
  },
  HGMA: {
    cx: 62,
    cy: 166,
  },
  GRIF: {
    cx: 64,
    cy: 152,
  },
  WALC: {
    cx: 66,
    cy: 145,
  },
  AZPS: {
    cx: 78,
    cy: 158,
  },
  SRP: {
    cx: 86,
    cy: 172,
  },
  IID: {
    cx: 46,
    cy: 167,
  },
  LDWP: {
    cx: 38,
    cy: 152,
  },
  TIDC: {
    cx: 22,
    cy: 126,
  },
  BANC: {
    cx: 18,
    cy: 102,
  },
  NEVP: {
    cx: 48,
    cy: 105,
  },
  PACW: {
    cx: 28,
    cy: 65,
  },
  PGE: {
    cx: 23,
    cy: 48,
  },
  AVRN: {
    cx: 36,
    cy: 42,
  },
  GRID: {
    cx: 46,
    cy: 44,
  },
  TPWR: {
    cx: 32,
    cy: 36,
  },
  SCL: {
    cx: 31,
    cy: 22,
  },
  PSEI: {
    cx: 40,
    cy: 28,
  },
  CHPD: {
    cx: 45,
    cy: 20,
  },
  DOPD: {
    cx: 50,
    cy: 24
  },
  AVA: {
    cx: 65,
    cy: 26,
  },
  GCPD: {
    cx: 55,
    cy: 32,
  },


  default: {
    cx: 0,
    cy: 0,
  },
};


export const regionToName: IStringMap = {
  'EIA.ISNE': 'ISO New England',
  'EIA.NYIS': 'New York ISO',
  'EIA.PJM': 'Pennsylvania, New Jersey, Deleware',
  'EIA.MISO': 'Mid-continent ISO',
  'EIA.SWPP': 'South West Power Pool',
  'EIA.BPAT': 'Bonneville Power Association',
  'EIA.CISO': 'California ISO',
  'YAD': 'Alcoa Power Generating, Inc. - Yadkin Division',
  'AZPS': 'Arizona Public Service Company',
  'DEAA': 'Arlington Valley, LLC',
  'AECI': 'Associated Electric Cooperative, Inc.',
  'AVRN': 'Avangrid Renewables, LLC'
}


export interface IRegionFlowProps {
  fromRegion: string;
  toRegion: string;
  isSelected: boolean;
  selectedRegions: string[];
  duration: string;
  color: string;
  isZero: boolean;
  flowSize: number;
}


const isRegionSelected = (
  selectedRegions: string[],
  currentRegions: string[],
) => {
  const allCurrentRegions: string[] = [];
  currentRegions.forEach(r => {
    allCurrentRegions.push(r);
    allCurrentRegions.push(`EIA.${r}`);
  });

  return allCurrentRegions.some(region => selectedRegions.indexOf(region) !== -1);
}


export const RegionFlow = ({
  fromRegion,
  toRegion,
  selectedRegions,
  duration,
  color,
  isZero,
  flowSize,
}: IRegionFlowProps) => {
  const fromProps = regionToCircleProps100p[fromRegion] || regionToCircleProps100p['EIA.' + fromRegion];
  const toProps = regionToCircleProps100p[toRegion] || regionToCircleProps100p['EIA.' + toRegion];
  const isSelected = isRegionSelected(selectedRegions, [toRegion, fromRegion]);

  if (toProps === undefined || fromProps === undefined) {
    // CAN or MEX region not supported yet
    return null;
  }

  const {
    cx: fromX,
    cy: fromY,
  } = fromProps;

  const {
    cx: toX,
    cy: toY,
  } = toProps;

  const d = `
  M ${fromX} ${fromY}
  L ${toX} ${toY}
  `;

  const pathOpacity = isSelected ? 0.3 : 0.05;
  const circleOpacity = isSelected ? 1 : 0.2;

  const pathColor = isZero ? 'var(--color-grey-4)' : color;

  return (
    <>
      <path d={d} stroke={pathColor} strokeWidth={flowSize} opacity={pathOpacity} />
      {!isZero &&
        <circle r={flowSize/2} cx={fromX} cy={fromY} fill={color} stroke={color} opacity={circleOpacity} >
          {isSelected &&
            <>
              <animate attributeName="cx" attributeType="CSS" from={fromX} to={toX} dur={duration} repeatCount="indefinite" />
              <animate attributeName="cy" attributeType="CSS" from={fromY} to={toY} dur={duration} repeatCount="indefinite" />
            </>
          }
        </circle>
      }
    </>
  );
}