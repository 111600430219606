import bbox from '@turf/bbox';
import { Map, NavigationControl, Popup } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';

import { IMapState, MapLevel, generateOverlapColors, getMapData, getMapOverlapData, rainbowColorMap10, singularitySelectStyle } from './utils';

import './style.css';

// Managed by singularityenergy account in 1Password. Only works on singularity.energy or qa-singularity.energy subdomains.
const MAPBOXGL_ACCESS_TOKEN = "pk.eyJ1Ijoic2luZ3VsYXJpdHllbmVyZ3kiLCJhIjoiY2xycXhrc2txMDdxajJpcGJneXV6M2M0ZSJ9.DMCeoe95ITJ5Ji-_IWdTtA";

const seMapboxContainerId = 'mapbox-container';
const mapDataSource = 'map-data-source';
const regionFillsLayer = 'region-fills';
const regionBordersLayer = 'region-borders';
const mapOverlapSource = 'map-overlap-source';
const mapOverlapLayer = 'map-overlap-layer';


// Store mapbox controls globally so that we can keep a reference to them.
var map: Map | null = null;
var mapNavigationControl = new NavigationControl({showCompass: false});
var labelPopup: Popup = null;

interface IMapLevelOption {
  value: MapLevel
  label: string
}

interface IMapRegionOption {
  value: string
  label: string
}

interface IMapViewProps {
  regionSelectCallback: (regions: string[]) => void
}


const MapView = ({regionSelectCallback}: IMapViewProps) => {
  const [mapState, setMapState] = useState({mapLevel: MapLevel.BAs, mapSelection: new Set<string>(), userEnteredSelection: false});
  const mapStateRef = useRef<IMapState>();
  mapStateRef.current = mapState;

  React.useEffect(() => {
    regionSelectCallback(Array.from(mapState.mapSelection));
    mapStateRef.current = mapState;
  }, [mapState.mapSelection]);

  const popupRef = useRef(new Popup({ offset: 15 }));

  // This function should add all of the sources and layers that the map will use. Other functions
  // change the map view by map.getSource(_).setData(_).
  const createMap = (onLoadCallback: () => void) => {
    const mapBoundingBox = [-124.73, 25.84, -66.98, 49.38];

    map = new Map({
      accessToken: MAPBOXGL_ACCESS_TOKEN,
      container: seMapboxContainerId,
      style: 'mapbox://styles/mapbox/light-v10',
      bounds: [mapBoundingBox[0] - 0.5, mapBoundingBox[1] - 0.5,
               mapBoundingBox[2] + 0.5, mapBoundingBox[3] + 0.5]
    });
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();

    map.on('load', () => {
      map.addSource(mapDataSource, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] },
        cluster: false,
      });

      map.addSource(mapOverlapSource, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] },
        cluster: false,
      });

      // Add zoom and rotation controls to the map.
      map.addControl(mapNavigationControl);

      // The feature-state dependent fill-opacity expression will render the hover effect
      // when a feature's hover state is set to true.
      map.addLayer({
        'id': regionFillsLayer,
        'type': 'fill',
        'source': mapDataSource,
        'layout': {
          // Make sure that smaller BAs show up on top of larger ones so that they're clickable.
          'fill-sort-key': ['-', ['get', 'area']]
        },
        'paint': {
          'fill-color': [
            'match',
            ['-', ['get', 'id'], ['*', ['floor', ['/', ['get', 'id'], 10]], 10]],
            ...rainbowColorMap10,
            '#000000'
          ],
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.8,
            0.5
          ],
        }
      });

      generateOverlapColors(map, () => {
        map.addLayer({
          'id': mapOverlapLayer,
          'type': 'fill',
          'source': mapOverlapSource,
          'layout': {
          },
          'paint': {
            'fill-opacity': 1.0,
            'fill-pattern': [
              'concat',
              'pattern-',
              ['-', ['get', 'id_1'], ['*', ['floor', ['/', ['get', 'id_1'], 10]], 10]],
              '-',
              ['-', ['get', 'id_2'], ['*', ['floor', ['/', ['get', 'id_2'], 10]], 10]]
            ]
          }
        });
      });

      map.addLayer({
        'id': regionBordersLayer,
        'type': 'line',
        'source': mapDataSource,
        'layout': {
          'line-sort-key': [
            'case',
            ['boolean', ['get', 'selected'], false],
            100,
            99,
          ],
        },
        'paint': {
          'line-color': [
            'case',
            ['boolean', ['get', 'selected'], false],
            '#000000',
            '#D3D3D3'
          ],
          'line-width': 1.0,
        }
      });

      // Create a popup, but don't add it to the map yet.
      labelPopup = new Popup({ closeButton: false, closeOnClick: false });

      let hoveredRegionId: any = null;

      // When the user moves their mouse over the state-fill layer, we'll update the
      // feature state for the feature under the mouse.
      map.on('mousemove', regionFillsLayer, (e) => {
        if (e.features.length > 0) {
          if (hoveredRegionId !== null) {
            map.setFeatureState(
              { source: mapDataSource, id: hoveredRegionId },
              { hover: false }
            );
          }
          hoveredRegionId = e.features[0].id;
          map.setFeatureState(
            { source: mapDataSource, id: hoveredRegionId },
            { hover: true }
          );

          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';

          // Copy coordinates array.
          if (e.features[0].properties.centroid) {
            // Note that this property will be a string, so we need to JSON parse it here.
            const coordinates = JSON.parse(e.features[0].properties.centroid).coordinates;
            const description = e.features[0].properties.name;
            labelPopup.setLngLat(coordinates).setHTML(description).addTo(map);
          }
        }
      });

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.on('mouseleave', regionFillsLayer, () => {
        if (hoveredRegionId !== null) {
          map.setFeatureState(
            { source: mapDataSource, id: hoveredRegionId },
            { hover: false }
          );
        }
        hoveredRegionId = null;

        // Remove label popup.
        map.getCanvas().style.cursor = '';
        labelPopup.remove();
      });

      // Use one global click event handler so that we can implement custom behavior.
      map.on('click', regionFillsLayer, (e) => {
        const region = e.features[0].properties.ba_code;
        if (mapStateRef.current.mapSelection.has(region)) {
          const newSelection = new Set(mapStateRef.current.mapSelection);
          newSelection.delete(region);
          setMapState({...mapStateRef.current, mapSelection: newSelection});
        } else if (mapStateRef.current.mapSelection.size < 10) {
          const newSelection = new Set(mapStateRef.current.mapSelection);
          newSelection.add(region);
          setMapState({...mapStateRef.current, mapSelection: newSelection});
        }
      });

      // Finally, update the map.
      onLoadCallback();
    });
  }

  // Update the map layers that are currently shown based on the props/state.
  const updateMap = useMemo(() => () => {
    if (!map || !map.getSource(mapDataSource) || !map.getSource(mapOverlapSource)) {
      return;
    }
    const mapDataToShow = getMapData(mapState);
    const overlapDataToShow = getMapOverlapData(mapState);

    const mapBoundingBox = bbox(mapDataToShow);
    map.fitBounds([mapBoundingBox[0] - 0.5, mapBoundingBox[1] - 0.5,
                  // Limit top right coordinates, we don't need to show all of Canada
                   Math.min(-65, mapBoundingBox[2] + 0.5), Math.min(53, mapBoundingBox[3] + 0.5)]);

    // @ts-ignore
    map.getSource(mapDataSource).setData(mapDataToShow);
    // @ts-ignore
    map.getSource(mapOverlapSource).setData(overlapDataToShow);

    if (popupRef.current) {
      popupRef.current.remove();
    }

  }, [mapState]);

  useEffect(() => {
    createMap(updateMap);
  }, []);

  useEffect(() => {
    updateMap();
  }, [mapState.mapLevel, mapState.mapSelection.size, mapState.userEnteredSelection, updateMap]);

  const mapLevelOptions = [
    {value: MapLevel.LBAs, label: "View BAs (Large)"},
    {value: MapLevel.ISOs, label: "View ISOs"},
    {value: MapLevel.BAs, label: "View BAs (All)"},
  ];

  const allMapRegionOptions: IMapRegionOption[] = [];
  const selectedMapRegionOptions: IMapRegionOption[] = [];

  // Make option objects for all SELECTED map regions.
  Array.from(mapState.mapSelection).forEach((selectedRegion) => {
    const option = { value: selectedRegion, label: selectedRegion };
    selectedMapRegionOptions.push(option);
    allMapRegionOptions.push(option);
  });

  if (!mapState.userEnteredSelection) {
    // Get all of the available map regions that the user can click right now.
    const mapDataToShow = getMapData(mapState);
    const mapRegionCodes = mapDataToShow.features.map((f: any) => {
      return f.properties.apiRegionCode;
    }).sort();

    mapRegionCodes.forEach((el: string) => {
      allMapRegionOptions.push({ value: el, label: el });
    });
  }

  return (
    <div className="se--map-panel">
      <div className="se--map-overlay-top-center">
        <Select
          isMulti={true}
          closeMenuOnSelect={false}
          className='react-select-toolbar'
          isClearable
          options={allMapRegionOptions}
          placeholder={'Select regions'}
          onChange={(value: IMapRegionOption[] | IMapRegionOption, _) => {
            if (!Array.isArray(value)) {
              value = [value];
            }
            if (value.length === 0 || value[0] === null) {
              setMapState({...mapState, mapSelection: new Set<string>() });
              return;
            }
            if (value.length > 10) {
              return;
            }
            setMapState({...mapState, mapSelection: new Set(value.map((el) => el.value))});
          }}
          value={selectedMapRegionOptions}
        />
      </div>
      <div className="se--map-overlay-top-right">
        <Select
          className='react-select-toolbar'
          options={mapLevelOptions}
          onChange={(value: IMapLevelOption, _) => setMapState({...mapState, mapLevel: value.value})}
          value={mapLevelOptions.find(el => (el.value === mapState.mapLevel))}
          styles={singularitySelectStyle}
        />
      </div>
      <div id={seMapboxContainerId}/>
    </div>
  );
}


export default MapView;
