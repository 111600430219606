import { ActionTypes, IUser, ICustomer, IAPIUsage } from './constants.ts';


export interface IUserAction {
  type: ActionTypes;
  payload: IReceiveCurrentUserPayload | IReceiveCurrentCustomerPayload | IReceiveAPIUsagePayload | IReceiveTeammatesPayload | IOnboarding;
}


interface IReceiveCurrentUserPayload {
  user: IUser;
}

export interface IReceiveTeammatesPayload {
  users: IUser[];
}

interface IReceiveCurrentCustomerPayload {
  customer: ICustomer;
}

interface IReceiveAPIUsagePayload {
  usage: IAPIUsage;
}

interface IOnboarding {
  onboarding_explore_data_done: boolean;
  onboarding_view_api_docs_done: boolean;
  onboarding_invite_more_team_done: boolean;
}


export const updateOnboarding = (onboarding: IOnboarding) => ({
  type: ActionTypes.UPDATE_ONBOARDING,
  payload: { onboarding },
})

export const receiveCurrentUser = (user: IUser) => ({
  type: ActionTypes.RECEIVE_CURRENT_USER,
  payload: { user },
});

export const receiveCurrentCustomer = (customer: ICustomer) => ({
  type: ActionTypes.RECEIVE_CURRENT_CUSTOMER,
  payload: { customer },
});

export const receiveAPIUsage = (usage: IAPIUsage) => ({
  type: ActionTypes.RECEIVE_API_USAGE,
  payload: { usage },
});

export const receiveTeammates = (users: IUser[]) => ({
  type: ActionTypes.RECEIVE_TEAMMATES,
  payload: { users },
})