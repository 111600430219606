import * as React from 'react';
import cx from 'classnames';

import Sidebar from 'components/shared/sidebar/index.tsx';
import LoadingDots from 'components/shared/loadingdots.tsx';

import { IRegionInfo } from './map.tsx';


const formatDate = (date: Date) => date.toLocaleString(window.navigator.language, {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric'})
const nFormatter = new Intl.NumberFormat(window.navigator.language);


interface IEIAMapSidebarProps {
  selectedRegionInfo: IRegionInfo;
  unsetHighlight: () => void;
  regionInfos: IRegionInfo[];
  setHoveredRegionInfo: (ri: IRegionInfo) => void;
  setSelectedRegionInfo: (ri: IRegionInfo) => void;
  selectedTime: string;
  isLoading: boolean;
}

enum ViewTypes {
  EMISSIONS = 'EMISSIONS',
  EMISSION_RATE = 'EMISSION_RATE',
}


const EIAMapSidebar = ({
  selectedRegionInfo,
  unsetHighlight,
  regionInfos,
  setHoveredRegionInfo,
  setSelectedRegionInfo,
  selectedTime,
  isLoading=false,
}: IEIAMapSidebarProps) => {

  const totalCarbonImport = Object.values(selectedRegionInfo.carbonFlow || {}).reduce((sofar: number, flow: number) => sofar + (isNaN(flow) ? 0 : flow), 0)/2000;
  const totalMWImport = -1 * Object.values(selectedRegionInfo.regionFlow || {}).reduce((sofar: number, flow: number) => sofar + (isNaN(flow) ? 0 : flow), 0);
  // consumed_rate * (generation + total_imports - total_exports)
  const consumptionEmissions = (selectedRegionInfo.consumedCI * (selectedRegionInfo.generation + totalMWImport))/2000;
  const consumptionRate = selectedRegionInfo.consumedCI;
  const productionEmissions = (selectedRegionInfo.genCI * selectedRegionInfo.generation)/2000
  const productionRate = selectedRegionInfo.genCI;

  const interchangeRows = Object.entries(selectedRegionInfo.carbonFlow || {}).map((([region, flow]: any) => {
    const regionInfo: IRegionInfo = regionInfos.find((d: IRegionInfo) => d.region === region || d.region === `EIA.${region}`);
    const formattedFlow = isNaN(flow / 2000) ? 'unreported' : nFormatter.format(flow / 2000);
    return (
      <tr
        key={region}
        onMouseEnter={() => setHoveredRegionInfo(regionInfo || {})}
        onMouseLeave={() => setHoveredRegionInfo({})}
        onClick={regionInfo ? () => setSelectedRegionInfo(regionInfo) : null}
        className={cx('region-flow--row', { clickable: !!regionInfo })}
      >
        <td>{region}</td>
        <td>{formattedFlow === '-0' ? '0' : formattedFlow}</td>
      </tr>
    )
  }));


  const [viewType, setViewType] = React.useState(ViewTypes.EMISSIONS);

  return (
    <Sidebar title="EIA Region Information" visible={!!selectedRegionInfo.region} onClose={unsetHighlight}>
      <div>
        <div className="eia-map-sidebar-title--container">
          <h2 className="eia-slideout--title">{(selectedRegionInfo.region || '').replace('EIA.', '')}</h2>
          <div className="eia-map-sidebar-rollup--options">
            <div className={cx('data-rollup--option', { selected: viewType === ViewTypes.EMISSIONS })} onClick={() => setViewType(ViewTypes.EMISSIONS)}>Emissions</div>
            <div className={cx('data-rollup--option', { selected: viewType === ViewTypes.EMISSION_RATE })} onClick={() => setViewType(ViewTypes.EMISSION_RATE)}>Carbon intensity</div>
          </div>
        </div>
        <div className="eia-slideout--subtitle">{formatDate(new Date(decodeURIComponent(selectedTime)))} (your time)</div>
        {isLoading &&
          <div className="eia-map--loading">Loading region data<LoadingDots /></div>
        }
        {!isLoading &&
          <>
            {viewType === ViewTypes.EMISSIONS &&
              <>
                <div className="eia-slideout-field--container purple">
                  <div className="eia-slideout-field--field">Production-based emissions this hour<div className="eia-slideout-field--unit">(tons of carbon)</div></div>
                  <div className="eia-slideout-field--value">{isNaN(productionEmissions) ? 'unreported' : nFormatter.format(productionEmissions)}</div>
                </div>
                <div className="eia-slideout-field--container green">
                  <div className="eia-slideout-field--field">Consumption-based emissions this hour<div className="eia-slideout-field--unit">(tons of carbon)</div></div>
                  <div className="eia-slideout-field--value">{isNaN(consumptionEmissions) ? 'unreported' : nFormatter.format(consumptionEmissions)}</div>
                </div>
              </>
            }
            {viewType === ViewTypes.EMISSION_RATE &&
              <>
                <div className="eia-slideout-field--container purple">
                  <div className="eia-slideout-field--field">Production-based Carbon intensity <div className="eia-slideout-field--unit">(lbs/MWh)</div></div>
                  <div className="eia-slideout-field--value">{isNaN(productionRate) ? 'unreported' : nFormatter.format(productionRate)}</div>
                </div>
                <div className="eia-slideout-field--container green">
                  <div className="eia-slideout-field--field">Consumption-based Carbon intensity <div className="eia-slideout-field--unit">(lbs/MWh)</div></div>
                  <div className="eia-slideout-field--value">{isNaN(consumptionRate) ? 'unreported' : nFormatter.format(consumptionRate)}</div>
                </div>
              </>
            }
            <div className="eia-slideout-field--container">
              <div className="eia-slideout-field--field dark">Interchange</div>
              <div>
                {!!interchangeRows.length && <table className="eia-slideout--table">
                  <thead>
                    <tr>
                      <th>Region</th>
                      <th>Imported emissions <div className="eia-slideout-field--unit">(tons of carbon)</div></th>
                    </tr>
                  </thead>
                  <tbody>{interchangeRows}</tbody>
                  <tfoot>
                    <tr>
                      <th scope="row">Total</th>
                      <th className="eia-slideout-table--td">{nFormatter.format(totalCarbonImport)}</th>
                    </tr>
                  </tfoot>
                </table>}
                {!interchangeRows.length &&
                  <div>no interchange reported</div>
                }
              </div>
            </div>
          </>
        }
      </div>
    </Sidebar>
  );
}

export default EIAMapSidebar;