import { Socket } from 'phoenix';
import { pluck, filter, mergeMap, flatMap } from 'rxjs/operators';
import { Observable, from, of, Observer } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';

import { ActionTypes as UserActionTypes } from 'modules/user/constants.ts';
import * as SocketActions from 'modules/socket/actions.ts';
import { ActionTypes } from 'modules/socket/constants.ts';
import { WS_URL } from 'constants/resources.ts';
import { getToken } from 'utils/auth.ts';
import { receivePresenceState, receivePresenceDiff } from 'modules/admin/actions.ts';
import { IConnectedUser } from 'modules/admin/constants.ts';
import { getPresenceContext } from 'utils/context.ts';

const socket = new Socket(`${WS_URL}/socket`, { params: { token: getToken() || "none" }});
socket.connect();
socket.onError(() => socket.disconnect());


// TODO: in a "public" site, we want to automatically connect to the "presence" channel
// so admins can see how many anonymous users are online:
// const channel = socket.channel("presence");
// channel.join()
//   .receive("ok", () => console.info('joined presence channel'))
//   .receive("error", () => console.warn('failed to join presence channel'));

const adminRoles = ['sadmin', 'uadmin'];

let connectedAlready: boolean = false;

const fetchOrgToJoinChannel = (action$: any) => action$.pipe(
  ofType(UserActionTypes.RECEIVE_CURRENT_USER),
  flatMap(({ payload: { user: { id, role } } }) => {
    if (connectedAlready) {
      return from([]);
    }
    const actions = [
      SocketActions.joinChannel({ channelName: `user:${id}` }),
      SocketActions.joinChannel({ channelName: 'presence', params: getPresenceContext() })
    ];
    if (adminRoles.indexOf(role) !== -1) {
      actions.push(SocketActions.joinChannel({ channelName: `admin:presence` }))
    }
    connectedAlready = true;
    return from(actions);
  })
)


const joinChannel = (action$: any) => action$.pipe(
  ofType(ActionTypes.JOIN_CHANNEL),
  pluck('payload'),
  mergeMap(({ channelName, params }) => {

    if (!socket.isConnected()) {
      return of(SocketActions.joinChannelFailed({ channelName }));
    }

    const pushToChannel$ = action$.pipe(
      ofType(ActionTypes.PUSH_TO_CHANNEL),
      pluck('payload'),
      filter(({ channelName: pushChannel }) => pushChannel === channelName)
    );

    return Observable.create((observer: Observer<any>) => {

      const channel = socket.channel(channelName, params);

      observer.next(SocketActions.joinChannelPending({ channelName }));
      channel.join()
        .receive("ok", () => observer.next(SocketActions.joinChannelSuccess({ channelName })))
        .receive("error", () => {
          channel.leave();
          return observer.next(SocketActions.joinChannelFailed({ channelName }))
        });

      pushToChannel$.subscribe(({ data }: any) => channel.push(channelName, data));

      channel.onMessage = (event: any, payload: any) => {
        const [singularity, eventName] = event.split(':');
        const isAcceptableEvent = singularity === 'singularity';
        const isPresence = event.indexOf('presence') !== -1;
        if (isAcceptableEvent) {
          observer.next({
            type: ActionTypes.RECEIVE_CHANNEL_MESSAGE,
            payload: {
              data: payload,
              eventType: eventName,
            }
          })
        } else if (isPresence) {
          if (event === 'presence_state') {
            const actionPayload = Object.entries(payload).map(([userId, { metas }]: any) => ({ userId, metas } as IConnectedUser));
            observer.next(receivePresenceState(actionPayload))
          } else if (event === 'presence_diff') {
            const leaves = Object.entries(payload.leaves).map(([userId, { metas }]: any) => ({ userId, metas } as IConnectedUser));
            const joins = Object.entries(payload.joins).map(([userId, { metas }]: any) => ({ userId, metas } as IConnectedUser));
            observer.next(receivePresenceDiff({ leaves, joins }));
          }
        }
        return payload
      };
    });
  }),
)


const eventTypesToActionTypes: any = {
  user_message: 'RECEIVE_MESSAGE',
  RECEIVE_CURRENT_CUSTOMER: 'RECEIVE_CURRENT_CUSTOMER',
}

const handleReceiveMessage = (action$: any) => action$.pipe(
  ofType(ActionTypes.RECEIVE_CHANNEL_MESSAGE),
  pluck('payload'),
  mergeMap(({ data, eventType }) => (of({
    type: eventTypesToActionTypes[eventType] || `UNKNOWN_WS_MSG:${eventType}`,
    payload: data,
  }))),
)


export default combineEpics(
  joinChannel,
  handleReceiveMessage,
  fetchOrgToJoinChannel,
)