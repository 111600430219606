import { isAdmin, isSystemAdmin } from 'utils/auth.ts';
import { IAttribute } from './constants.ts';

const root = (state: any) => state.user;

export const currentUser = (state: any) => root(state).currentUser;
export const currentUserIsAdmin = (state: any) => isAdmin((currentUser(state) || {}).role)
export const currentUserIsSystemAdmin = (state: any) => isSystemAdmin((currentUser(state) || {}).role)
export const currentCustomer = (state: any) => root(state).currentCustomer || {};
export const apiKeyValue = (state: any) => currentCustomer(state).api_key_value;
export const apiUsage = (state: any) => root(state).apiUsage;
const currentUserAttributes = (state: any): IAttribute[] => (currentUser(state) || {}).attributes || []
const getAttrValue = (state: any, attrName: string) => (currentUserAttributes(state).find((attr) => attr.name === attrName) || {}).value
export const defaultRegion = (state: any) => getAttrValue(state, 'default_region') as string
export const exploreDataOnboardingDone = (state: any) => (getAttrValue(state, 'onboarding_explore_data_done') as boolean) || false
export const viewAPIDocumentationOnboardingDone = (state: any) => (getAttrValue(state, 'onboarding_view_api_docs_done') as boolean) || false
export const inviteMoreTeamOnboardingDone = (state: any) => (getAttrValue(state, 'onboarding_invite_more_team_done') as boolean) || false
export const hasAnyOnboardingTasksLeft = (state: any) => !exploreDataOnboardingDone(state) || !viewAPIDocumentationOnboardingDone(state) || !inviteMoreTeamOnboardingDone(state)
