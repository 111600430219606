import produce from 'immer';

import { ActionTypes, IUser, ICustomer, IAPIUsage } from './constants.ts';
import { IUserAction, IReceiveTeammatesPayload  } from './actions.ts';

interface IState {
  currentCustomer: ICustomer;
  currentUser: IUser;
  apiUsage: IAPIUsage;
  teammates: IUser[];
}


const defaultState: IState = {
  currentUser: null,
  currentCustomer: null,
  apiUsage: null,
  teammates: null,
};

const receiveCurrentUser = (newState: any, { user }: any) => {
  newState.currentUser = user;
  return newState;
}

const receiveCurrentCustomer = (newState: any, { customer }: any) => {
  newState.currentCustomer = customer;
  return newState;
}

const receiveAPIUsage = (newState: any, { usage }: any) => {
  newState.apiUsage = usage;
  return newState;
}

const receiveTeammates = (newState: any, { users }: IReceiveTeammatesPayload) => {
  newState.teammates = users;
  return newState;
}


export default (state = defaultState, action: IUserAction) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_CURRENT_USER:
      return produce(state, (draftState: any) => receiveCurrentUser(draftState, action.payload));
    case ActionTypes.RECEIVE_CURRENT_CUSTOMER:
      return produce(state, (draftState: any) => receiveCurrentCustomer(draftState, action.payload));
    case ActionTypes.RECEIVE_API_USAGE:
      return produce(state, (draftState: any) => receiveAPIUsage(draftState, action.payload));
    case ActionTypes.RECEIVE_TEAMMATES:
      return produce(state, (draftState: any) => receiveTeammates(draftState, action.payload));
    default:
      return state;
  }
};