import * as React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';

import EIAConsumedRateMap from 'components/eia-map/index.tsx';


const MapLayout = () => {

  return (
    <div className="carbonara-settings-menu--container">
      <div className="carbonara-settings-nav--container">
        <h3>US Map (beta)</h3>
      </div>
      <Switch>
        <Route path="/map" exact render={() => <Redirect to="/map/us" />} />
        <Route path="/map/us" component={EIAConsumedRateMap} />
      </Switch>
    </div>
  )
}


export default withRouter(MapLayout)