const THREE_MINUTES = 1000 * 60 * 3;
const FORTY_MINUTES = 1000 * 60 * 40;


const root = (state: any) => state.reports

const carbonTrends = (state: any) => root(state).carbonTrendData
const hourlyCarbonTrends = (state: any) => root(state).hourlyCarbonTrendData
const carbonTrendForRegion  = (state: any, region: string) => carbonTrends(state)[region] || {}
const hourlyCarbonTrendForRegion  = (state: any, region: string) => hourlyCarbonTrends(state)[region] || {}
export const carbonTrendForRegionFetched = (state: any, region: string) => {
  const lastFetched = carbonTrendForRegion(state, region).lastFetched || 0;
  const now = Date.now();
  if (lastFetched === 0) {
    return false;
  } else {
    return now - lastFetched <= THREE_MINUTES;
  }
}
export const hourlyCarbonTrendForRegionFetched = (state: any, region: string) => {
  const lastFetched = hourlyCarbonTrendForRegion(state, region).lastFetched || 0;
  const now = Date.now();
  if (lastFetched === 0) {
    return false;
  } else {
    return now - lastFetched <= FORTY_MINUTES;
  }
}
export const carbonTrendData = (state: any, region: string) => carbonTrendForRegion(state, region).data;
export const hourlyCarbonTrendData = (state: any, region: string) => hourlyCarbonTrendForRegion(state, region).data;


const homeReports = (state: any) => root(state).homeData
const homeReportForRegion = (state: any, region: string) => homeReports(state)[region] || {}
export const homeReportLastFetched = (state: any, region: string) => homeReportForRegion(state, region).lastFetched || 0;
export const homeReportForRegionFetched = (state: any, region: string) => {
  const lastFetched = homeReportLastFetched(state, region);
  const now = Date.now();
  if (lastFetched === 0) {
    return false;
  } else {
    return now - lastFetched <= THREE_MINUTES;
  }
}
export const homeReportData = (state: any, region: string) => homeReportForRegion(state, region).data;


const fuelmixTrends = (state: any) => root(state).fuelmixTrendData
const hourlyFuelmixTrends = (state: any) => root(state).hourlyFuelmixTrendData
const fuelmixTrendForRegion  = (state: any, region: string) => fuelmixTrends(state)[region] || {}
const hourlyFuelmixTrendForRegion  = (state: any, region: string) => hourlyFuelmixTrends(state)[region] || {}
export const fuelmixTrendForRegionFetched = (state: any, region: string) => {
  const lastFetched = fuelmixTrendForRegion(state, region).lastFetched || 0;
  const now = Date.now();
  if (lastFetched === 0) {
    return false;
  } else {
    return now - lastFetched <= THREE_MINUTES;
  }
}
export const hourlyFuelmixTrendForRegionFetched = (state: any, region: string) => {
  const lastFetched = hourlyFuelmixTrendForRegion(state, region).lastFetched || 0;
  const now = Date.now();
  if (lastFetched === 0) {
    return false;
  } else {
    return now - lastFetched <= FORTY_MINUTES;
  }
}
export const fuelmixTrendData = (state: any, region: string) => fuelmixTrendForRegion(state, region).data;
export const hourlyFuelmixTrendData = (state: any, region: string) => hourlyFuelmixTrendForRegion(state, region).data;


const eiaUSMapRoot = (state: any) => root(state).eiaUSMap;
export const mapData = (state: any, timestr: string) => eiaUSMapRoot(state)[timestr];

const mapStatuses = (state: any) => root(state).mapStatuses;
export const getMapStatus = (state: any, timestr: string) => mapStatuses(state)[timestr];
export const mapStatusNeverLoaded = (state: any, timestr: string) => getMapStatus(state, timestr) === undefined;
export const mapStatusLoading = (state: any, timestr: string) => getMapStatus(state, timestr) === 'LOADING';
export const mapStatusFailed = (state: any, timestr: string) => getMapStatus(state, timestr) === 'FAILED';
export const mapStatusDone = (state: any, timestr: string) => getMapStatus(state, timestr) === 'DONE';