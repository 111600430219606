import * as React from 'react';

import LoadingDots from 'components/shared/loadingdots.tsx';
import { getInstance } from 'api/index.ts';

import './style.css';


interface IVerifyEmailProps {
  location: any;
  history: any;
}


const VerifyEmail = ({
  location,
}: IVerifyEmailProps) => {

  const params = new URLSearchParams(location.search);
  const token = params.get('verification_token');

  const [isVerified, setIsVerified] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const http = getInstance();
    http.post('/api/v1/users/verification', { token })
      .then(() => {
        setLoading(false);
        setIsVerified(true);
        setTimeout(() => (window.location as any) = '/app/home', 1200)
      })
      .catch(err => {
        console.warn(err);
        setLoading(false);
        setError('Failed to verify. Please contact support if you need assistance.');
      })
  }, []);

  return (
    <div className="carbonara-verification--container">
      {loading && <div className="carbonara-verifying-account--loading">Verifing your account<LoadingDots /></div>}
      {isVerified && <div>You've successfully verified your account!</div>}
      {error && <div>{error}</div>}
    </div>
  )
}


export default VerifyEmail;