let ResourcesConstants: any;
declare const ENVIRONMENT: string;

if (ENVIRONMENT === 'production') {
  ResourcesConstants = {
    WS_URL: 'wss://live.api.singularity.energy',
    API_URL: 'https://api.carbonara.singularity.energy',
    AUTH_URL: 'https://login.singularity.energy',
  }
} else if (ENVIRONMENT === 'qa') {
  ResourcesConstants = {
    WS_URL: 'wss://live.api.qa-singularity.energy',
    AUTH_URL: 'https://login.qa-singularity.energy',
    API_URL: 'https://api.carbonara.qa-singularity.energy',
  }
} else {
  ResourcesConstants = {
    WS_URL: 'ws://localhost:4000',
    API_URL: 'http://lcl.qa-singularity.energy:5010',
    AUTH_URL: 'http://lcl.qa-singularity.energy:8010',
  }
}

export const DEBUG = ENVIRONMENT !== 'production'
export const API_URL = ResourcesConstants.API_URL
export const WS_URL = ResourcesConstants.WS_URL
export const AUTH_URL = ResourcesConstants.AUTH_URL