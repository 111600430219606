import * as React from 'react';
import { connect } from 'react-redux';

import LoadingDots from 'components/shared/loadingdots.tsx';
import { getInstance } from 'api/index.ts';
import { currentCustomer } from 'modules/user/selectors.ts';
import { ICustomer } from 'modules/user/constants';

import './style.css';


const WebhooksRedirect = ({customer}: {customer: ICustomer}) => {

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const hasWebhooks = customer.attributes?.find(x => x.name === "has_webhooks")?.value === true;

  React.useEffect(() => {
    if (hasWebhooks) {
      const http = getInstance();
      http.get('/api/v1/customers/me/webhooks-redirect')
        .then((response: any) => {
          setLoading(false);
          window.location.replace(response.data);
        })
        .catch(err => {
          console.warn(err);
          setLoading(false);
          setError('Failed to launch webhooks portal. Please contact support if you need assistance.');
        })
    } else {
      setLoading(false);
      setError('Webhooks are not enabled for your account. Please contact support if you need assistance.')
    }
  }, []);

  return (
    <div className="carbonara-webhooks-redirect--container">
      {loading && <div className="carbonara-webhooks-redirect--loading">Launching webhooks portal<LoadingDots /></div>}
      {error && <div>{error}</div>}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  customer: currentCustomer(state)
});


export default connect(mapStateToProps)(WebhooksRedirect);