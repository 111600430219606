import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { groupBy, prop, indexBy, sum, flatten, equals } from 'ramda';
import cx from 'classnames';

import { getInstance } from 'api/index.ts';
import { mapData, mapStatusNeverLoaded } from 'modules/reports/selectors.ts';
import { receiveEIAUSMap, receiveMapStatus } from 'modules/reports/actions.ts';
import LoadingDots from 'components/shared/loadingdots.tsx';
import { q20, q40, q60, q80, normalize } from 'utils/math.ts';
import mapSrc from 'images/usa.png';

import { regionToCircleProps100p, RegionFlow, IRegionInfo } from './map.tsx';
import EIAMapSidebar from './sidebar.tsx';
import MapTimePicker from './timepicker.tsx';
import MapLegend from './legend.tsx';
import './style.css';

const regionToCircleProps = regionToCircleProps100p


const neighborRegions = [
  'NBSO',
  'IESO',
  'BCHA',
  'HQT',
  'MHEB',
  'AESO',
  'CFE',
  'CEN',
];


const MIN_R: number = 6;
const MAX_R: number = 22;
const MIN_DUR: number = 0.4;
const MAX_DUR: number = 2;
const MIN_STROKE: number = 4;
const MAX_STROKE: number = 10;


const max = (series: number[]) => series.reduce((s, c) => s > c ? s : c, 0);
const min = (series: number[]) => series.reduce((s, c) => s < c ? s : c, 0);

const makeRegionInfos = (regionAndEvents: any) => {
  const regionInfos: IRegionInfo[] = regionAndEvents.map(([region, eventMap]: any) => {
    let generation = eventMap.generated_fuel_mix;
    if (generation) {
      generation = sum(Object.values(generation.data));
    } else {
      generation = null;
    }

    let regionFlow;
    let carbonFlow: any = {};
    if (eventMap.region_flow) {
      regionFlow = eventMap.region_flow.data;
      Object.entries(eventMap.region_flow.data).forEach(([region2, mw]: any) => {
        // TODO: support defaults for neighboring/missing regions
        const otherRegion = regionAndEvents.find((d: any) => d[0] === region2 || d[0] === `EIA.${region2}`)
        if (otherRegion && otherRegion[1].carbon_intensity && eventMap.carbon_intensity) {
          const consumedRate = mw > 0 ? eventMap.carbon_intensity.data.consumed_rate : (otherRegion[1].carbon_intensity.data.consumed_rate || (otherRegion[1].carbon_intensity.data.generated_rate * -1));
          carbonFlow[region2] = -1 * (mw * consumedRate);
        }
      })
    }

    const ci = eventMap.carbon_intensity;
    let genCI;
    let consumedCI;
    if (ci) {
      genCI = ci.data.generated_rate;
      consumedCI = ci.data.consumed_rate;
    } else {
      genCI = null;
      consumedCI = null;
    }

    return {
      region,
      generation,
      genCI,
      consumedCI,
      regionFlow,
      carbonFlow,
    };
  }).filter((d: IRegionInfo) => d.generation !== null && d.genCI !== null && d.consumedCI !== null);

  const ciRates = regionInfos.map(d => d.consumedCI || d.genCI);

  const q20GenRate = q20(ciRates);
  const q40GenRate = q40(ciRates);
  const q60GenRate = q60(ciRates);
  const q80GenRate = q80(ciRates);

  return regionInfos.map(r => {
    return {
      color: getColor(r.consumedCI || r.genCI, q20GenRate, q40GenRate, q60GenRate, q80GenRate),
      ...r,
    }
  })
}

const makeExports = (regionInfos: IRegionInfo[]) => {
  // make a list of:
  // { fromRegion: ..., toRegion: ..., flow: number }
  // from only the exports in the region infos
  const exportFlows: any[] = flatten(
    regionInfos
      .filter(({ regionFlow }) => !!regionFlow)
      .map(region => {
        return Object.entries(region.regionFlow)
          .filter(([otherRegion, flow]) => flow >= 0 || neighborRegions.includes(otherRegion))
          .map(([otherRegion, flow]) => {
            return {
              fromRegion: region.region,
              toRegion: otherRegion,
              color: region.color,
              flow
            };
          });
      })
  );

  const maxFlow = max(exportFlows.map(d => d.flow));
  const minFlow = min(exportFlows.map(d => d.flow));

  return exportFlows.map((flow) => {
    const flowSize = normalize(flow.flow, maxFlow, minFlow, MAX_STROKE, MIN_STROKE);
    // ceiling and floor arguments are switched so that
    //higher flow rates have a shorter animation duration
    const duration = normalize(flow.flow, maxFlow, minFlow, MIN_DUR, MAX_DUR);
    return {
      duration,
      flowSize,
      isZero: flow.flow === 0,
      ...flow
    };
  });
}

const getColor = (num: number, q20: number, q40: number, q60: number, q80: number) => {
  if (num <= q20) {
    return '#33c7b0';
  } else if (num <= q40) {
    return '#8edde2';
  } else if (num <= q60) {
    return '#db88d3';
  } else if (num <= q80) {
    return '#ffb476';
  } else {
    return '#ff5a5b';
  }
}

const makeCircles = (
  regionInfos: IRegionInfo[],
  onClick: (regionInfo: IRegionInfo) => void,
  highlightedRegion: string,
  hoveredRegion: string,
  setHoveredRegion: (reginInfo: IRegionInfo) => void,
) => {
  const genfuelMixes = regionInfos.map(d => d.generation);

  const maxGeneration = max(genfuelMixes);
  const minGeneration = min(genfuelMixes);

  const sizes = regionInfos.map(({ generation, region}: IRegionInfo) => {
    const size = normalize(generation, maxGeneration, minGeneration, MAX_R, MIN_R);
    return { size, region };
  });

  return sizes.map(({ size, region }: any) => {
    const regionInfo: IRegionInfo = regionInfos.find(d => d.region === region);
    let extraProps = regionToCircleProps[regionInfo.region];
    if (!extraProps) {
      extraProps = regionToCircleProps.default;
    }
    const highlighted = highlightedRegion === regionInfo.region;
    const hovered = hoveredRegion === regionInfo.region;
    return <React.Fragment key={regionInfo.region}>
      <circle
        r={`${highlighted ? size + 4 : size}`}
        className="us-eia-map--outer-circle"
        {...extraProps}
        fill="transparent"
        color={regionInfo.color}
        stroke={highlighted ? regionInfo.color : 'transparent'}
      />
      <circle
        onMouseEnter={() => setHoveredRegion(regionInfo)}
        onMouseLeave={() => setHoveredRegion({})}
        onClick={() => onClick(regionInfo)}
        className={cx("us-eia-map--circle", { hovered: hovered && !highlighted, highlighted })}
        {...extraProps}
        r={`${size}`}
        color={regionInfo.color}
        stroke={regionInfo.color}
        fill={regionInfo.color}
      />
    </React.Fragment>;
  });
}


const EIAMap = () => {

  const [selectedTime, setSelectedTime]: any[] = React.useState();
  const data = useSelector(state => mapData(state, selectedTime));
  const neverLoaded = useSelector(state => mapStatusNeverLoaded(state, selectedTime));
  const [selectedRegionInfo, setSelectedRegionInfo]: any[] = React.useState({});
  const [hoveredRegionInfo, setHoveredRegionInfo]: any[] = React.useState({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (neverLoaded && selectedTime) {
      dispatch(receiveMapStatus(selectedTime, 'LOADING'))
      getInstance()
        .get(`/api/v1/analysis/eia-map?time=${selectedTime}`)
        .then((d: any) => {
          dispatch(receiveMapStatus(selectedTime, 'DONE'))
          dispatch(receiveEIAUSMap(selectedTime, d.data))
        })
        .catch(e => {
          console.warn(e)
          dispatch(receiveMapStatus(selectedTime, 'FAILED'))
        })
    }
  }, [neverLoaded, selectedTime]);

  const regionAndEvents = Object.entries(
    groupBy(
      prop('region'),
      data || [],
    )
  ).map(([region, d]: any) => ([region, indexBy(prop('event_type'), d)]))

  const onCircleClick = (regionInfo: IRegionInfo) => setSelectedRegionInfo(regionInfo);
  const regionInfos = makeRegionInfos(regionAndEvents);
  const exports = makeExports(regionInfos);

  const circles = makeCircles(regionInfos, onCircleClick, selectedRegionInfo.region, hoveredRegionInfo.region, setHoveredRegionInfo);
  const unsetHighlight = () => setSelectedRegionInfo({});

  React.useEffect(() => {
    if (!equals(selectedRegionInfo, {})) {
      const newRegionInfo = regionInfos.find(d => d.region === selectedRegionInfo.region);
      if (newRegionInfo) {
        setSelectedRegionInfo(newRegionInfo);
      }
    }
  }, [data])

  if (!data) {
    return <div>
        <MapTimePicker selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
        <div className="eia-map--loading">Loading all US info<LoadingDots/></div>
        <EIAMapSidebar
          selectedRegionInfo={selectedRegionInfo}
          regionInfos={regionInfos}
          setHoveredRegionInfo={setHoveredRegionInfo}
          setSelectedRegionInfo={setSelectedRegionInfo}
          unsetHighlight={unsetHighlight}
          selectedTime={selectedTime}
          isLoading={true}
        />
      </div>
  }

  return (
    <div>
      <div>
        <MapTimePicker selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
      </div>
      <svg viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg" stroke="red" fill="grey" width="70%">
        <image href={mapSrc} className="map-bg--image" width="100%" style={{opacity: 0.25}}/>
        {exports.map(flowExport =>
          <RegionFlow
            key={`${flowExport.fromRegion}->${flowExport.toRegion}`}
            {...flowExport}
            selectedRegions={[selectedRegionInfo.region, hoveredRegionInfo.region]}
          />
        )}
        {circles}
      </svg>
      <div>
        <MapLegend />
      </div>
      <EIAMapSidebar
        selectedRegionInfo={selectedRegionInfo}
        regionInfos={regionInfos}
        setHoveredRegionInfo={setHoveredRegionInfo}
        setSelectedRegionInfo={setSelectedRegionInfo}
        unsetHighlight={unsetHighlight}
        selectedTime={selectedTime}
      />
    </div>
  );
};


export default EIAMap;