import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Select from 'react-select';

import { bootToLogout, getInstance } from 'api/index.ts';
import Button from 'components/shared/button.tsx';
import Modal from 'components/shared/modal/index.tsx';
import UserIcon from 'components/shared/user-icon/index.tsx';
import { ICustomer, IUser } from 'modules/user/constants.ts';
import { currentUserIsSystemAdmin } from 'modules/user/selectors.ts';
import { isThinDevice } from 'utils/userAgent.ts';

import './style.css';


interface INavProps {
  isLoggedIn: boolean;
  user: IUser;
  customer: ICustomer;
  allCustomers: ICustomer[];
  isSuperAdmin: boolean;
  history: any;
  location: any;
  hasAnalysisEnabled: boolean;
};

interface IDropdownProps {
  user: IUser;
  customer: ICustomer;
  isSuperAdmin: boolean;
  isOpen: boolean;
  onClose?: () => void;
  allCustomers: ICustomer[];
  openChangeCustomerModal: any;
}

const signOut = () => {
  bootToLogout();
};


class Dropdown extends React.Component<IDropdownProps> {
  constructor(props: IDropdownProps) {
    super(props);
  }

  handleClickOutside = () => {
    this.props.onClose();
  };

  openChat = () => {
    const win: any = window;
    win.drift.api.showWelcomeMessage(
      'Need anything? I\'ll get a push notification as soon as you send a message and we can start chatting!',
    );
  }

  render() {
    const {
      user,
      customer,
      isSuperAdmin,
      isOpen,
      openChangeCustomerModal,
    } = this.props;

    if (!isOpen) return null

    return (
      <div className="carbonara-nav-dropdown--contianer">
        <div className="carbonara-nav-dropdown--section">
          <h5 className="carbonara-nav-dropdown--name">{user.name}</h5>
          <h6  className="carbonara-nav-dropdown--customer">{customer.name}</h6>
        </div>
        <div className="carbonara-nav-dropdown--section link" onClick={this.openChat}>Need help?</div>
        {isSuperAdmin && <div className="carbonara-nav-dropdown--section link" onClick={openChangeCustomerModal}>Change account</div>}
        {isSuperAdmin && <Link className="carbonara-nav-dropdown--section link" to="/admin">Admin</Link>}
        <div onClick={signOut} className="carbonara-nav-dropdown--section link">Logout</div>
      </div>
    )
  }
}

const ClickOutsideDropdown = onClickOutside(Dropdown);

const ChangeCustomerModal = ({
  allCustomers,
  currentCustomer,
  currentUser,
  ...modalProps
}: any) => {

  const [selectedCustomer, setSelectedCustomer] = React.useState(currentCustomer && { value: currentCustomer.id, label: currentCustomer.name });
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const CustomerOptions = allCustomers.map(({ id, name }: ICustomer) => ({ value: id, label: name }))

  const submit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    getInstance()
      .patch(`/api/v1/users/${currentUser.id}`, { user: { customer_id: selectedCustomer.value }})
      .then(() => {
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        setError(err.error_message);
      })
  }

  return (
    <Modal {...modalProps}>
      <div className="carbonara-change-account--modal">
        <label>
          account
          <Select options={CustomerOptions} value={selectedCustomer} onChange={setSelectedCustomer}/>
        </label>
        <Button loading={isLoading} buttonType="submit" onClick={submit} className="carbonara-change-account--button">Submit</Button>
        {error && <div className="invite-user--error">{error}</div>}
      </div>
    </Modal>
  )
}




const TopNav = ({
  isLoggedIn,
  user,
  allCustomers,
  customer,
  isSuperAdmin,
  history,
  location
}: INavProps) => {
  if (!isLoggedIn) return null;
  if (location.pathname === '/status') return null;
  if (!customer && !isSuperAdmin) return null;

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [changeCustomerModalOpen, setChangeCustomerModalOpen] = React.useState(false);

  return (
    <div>
      <div className="carbonara-nav--container">
        <div className="carbonara-nav-title--container" onClick={() => history.push('/home')}>
          <h3 className="carbonara-nav--title">{window.location.hostname.includes("carbonara") ? "Carbonara" : "Grid Carbon"}</h3>
          <div className="carbonara-nav--subtitle">By Singularity</div>
        </div>
        <div className="carbonara-nav-links--container">
          {!isThinDevice() &&
            <React.Fragment>
              <NavLink to="/settings/api" activeClassName="active" className="carbonara-nav-link">
                API Management
              </NavLink>
              <NavLink to="/home" activeClassName="active" className="carbonara-nav-link">
                Dashboard
              </NavLink>
              <NavLink to="/map" activeClassName="active" className="carbonara-nav-link">
                Map
              </NavLink>
              <NavLink to="/settings/team" activeClassName="active" className="carbonara-nav-link">
                Team
              </NavLink>
            </React.Fragment>
          }
          <div className="carbonara-nav-link carbonara-nav-sign-out-button">
            <UserIcon user={user} onClick={() => setDropdownOpen(!dropdownOpen)} />
          </div>
        </div>
      </div>
      <ClickOutsideDropdown
        user={user}
        isOpen={dropdownOpen}
        onClose={() => setDropdownOpen(false)}
        allCustomers={allCustomers}
        customer={customer}
        isSuperAdmin={isSuperAdmin}
        openChangeCustomerModal={() => setChangeCustomerModalOpen(true)}
      />
      {isSuperAdmin && allCustomers && <ChangeCustomerModal
        isOpen={changeCustomerModalOpen}
        onClose={() => setChangeCustomerModalOpen(false)}
        allCustomers={allCustomers}
        currentCustomer={customer}
        currentUser={user}
        title="Change account"
      />}
    </div>
  );

}


const mapStateToProps = (state: any) => ({
  isLoggedIn: !!state.user.currentUser,
  user: state.user.currentUser,
  customer: state.user.currentCustomer,
  allCustomers: state.admin.customerPage.customers,
  isSuperAdmin: currentUserIsSystemAdmin(state)
});


export default connect(mapStateToProps)(withRouter(TopNav as any));