import { ActionTypes, IConnectedUser } from 'modules/admin/constants.ts';
import { ICustomer } from 'modules/user/constants.ts';


export interface IUserAction {
  type: ActionTypes;
  payload: IReceiveAllCustomersPayload;
}


interface IReceiveAllCustomersPayload {
  customers: ICustomer[];
}

export interface IPagination {
  first: number;
  last: number;
  next?: number;
  this: number;
  prev?: number;
}

export interface IConnectedChange {
  joins: IConnectedUser[];
  leaves: IConnectedUser[];
}


export const receiveAllCustomers = (customers: ICustomer[], pagination: IPagination) => ({
  type: ActionTypes.RECEIVE_ALL_CUSTOMERS,
  payload: { customers, pagination },
});


export const receivePresenceState = (connectedUsers: IConnectedUser[]) => ({
  type: ActionTypes.RECEIVE_PRESENCE_STATE,
  payload: { presence: connectedUsers },
});


export const receivePresenceDiff = (connectionChange: IConnectedChange) => ({
  type: ActionTypes.RECEIVE_PRESENCE_DIFF,
  payload: { connectionChange }
});