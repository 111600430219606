import axios, { AxiosResponse } from 'axios';

import { API_URL, AUTH_URL } from "constants/resources.ts";
import { getToken } from "utils/auth.ts";


export interface IHTTPConfig {
  useToken: boolean;
  useCSRF: boolean;
}

const defaultConfig: IHTTPConfig = {
  useToken: true,
  useCSRF: true,
};

const productId = window.location.hostname.split('.')[0];

export const bootToLogin = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: productId,
  });

  window.location.href = `${AUTH_URL}/?${params}`;
}

export const bootToLogout = () => {
  const params = new URLSearchParams({
    return_to: encodeURIComponent(window.location.href),
    product: productId,
  });

  window.location.href = `${AUTH_URL}/logout?${params}`;
}


class HTTP {

  private csrfToken: string;

  public constructor() {}

  private async refreshCSRF() {
    const headers: any = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    };
    const res = await fetch(`${API_URL}/csrf-token`, { headers, credentials: 'include' })
    const { token } = await res.json();
    this.csrfToken = token;
  }

  private async getCSRF() {
    if (!this.csrfToken) {
      await this.refreshCSRF()
    }

    return this.csrfToken;
  }

  private async checkStatus(res: Response) {
    if (res.status === 401) {
      bootToLogin();
    }

    if (!res.ok) {
      let badRes;
      try {
        badRes = await res.json();
      } catch (e) {
        badRes = res;
      }
      throw badRes;
    }

    return await res.json();
  };

  private checkAxiosStatus(res: AxiosResponse<any>) {
    if (res.status === 401) {
      bootToLogin();
    }
    if (res.status > 299 || res.status < 200) {
      let badRes;
      try {
        badRes = res.data;
      } catch (e) {
        badRes = res;
      }
      throw badRes;
    }

    return res.data;
  }

  public async post(path: string, body: object, config: IHTTPConfig = defaultConfig): Promise<any> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (config.useToken) {
      headers['Authorization'] = `Bearer ${getToken()}`;
    }

    if (config.useCSRF) {
      headers['X-CSRFToken'] = await this.getCSRF();
    }

    let res = await fetch(`${API_URL}${path}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
      credentials: 'include',
    });

    if (res.status === 499) {
      await this.refreshCSRF();

      headers['X-CSRFToken'] = await this.getCSRF();

      res = await fetch(`${API_URL}${path}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
        credentials: 'include',
      });
    }

    return this.checkStatus(res);
  }

  public async patch(path: string, body: object, config: IHTTPConfig = defaultConfig): Promise<any> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (config.useToken) {
      headers['Authorization'] = `Bearer ${getToken()}`;
    }

    if (config.useCSRF) {
      headers['X-CSRFToken'] = await this.getCSRF();
    }

    let res = await fetch(`${API_URL}${path}`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(body),
      credentials: 'include',
    });

    if (res.status === 499) {
      await this.refreshCSRF();

      headers['X-CSRFToken'] = await this.getCSRF();

      res = await fetch(`${API_URL}${path}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(body),
        credentials: 'include',
      });
    }

    return this.checkStatus(res);
  }


  public async get(path: string, config: IHTTPConfig = defaultConfig): Promise<any> {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (config.useToken) {
      headers['Authorization'] = `Bearer ${getToken()}`;
    }

    if (config.useCSRF) {
      headers['X-CSRFToken'] = await this.getCSRF();
    }

    let res = await axios.get(`${API_URL}${path}`, {
      method: 'GET',
      headers: headers,
      withCredentials: true,
    });

    if (res.status === 499) {
      await this.refreshCSRF();

      headers['X-CSRFToken'] = await this.getCSRF();

      res = await axios.get(`${API_URL}${path}`, {
        method: 'GET',
        headers: headers,
        withCredentials: true,
      });
    }

    return this.checkAxiosStatus(res);
  }

}

const instance = new HTTP();

export const getInstance = () => instance