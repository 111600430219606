import * as React from 'react';
import cx from 'classnames';

import './style.css';


interface IBadgeProps {
  background: string;
  color: string;
  className?: string;
  children: any;
}


const Badge = ({
  background,
  color,
  className = '',
  children,
}: IBadgeProps) => (
  <div className={cx('carbonara-badge', className)} style={{background: background, color: color}}>
    {children}
  </div>
)


export default Badge;