import { ActionTypes } from 'modules/socket/constants.ts';


export interface ISocketAction {
  type: ActionTypes;
  payload: any;
};


export const joinChannel = ({ channelName, params={} }: any) => ({
  type: ActionTypes.JOIN_CHANNEL,
  payload: { channelName, params },
});

export const joinChannelPending = ({ channelName }: any) => ({
  type: ActionTypes.JOIN_CHANNEL_PENDING,
  payload: { channelName },
});

export const joinChannelSuccess = ({ channelName }: any) => ({
  type: ActionTypes.JOIN_CHANNEL_SUCCESS,
  payload: { channelName },
});

export const joinChannelFailed = ({ channelName }: any) => ({
  type: ActionTypes.JOIN_CHANNEL_FAILED,
  payload: { channelName },
});

export const pushToChannel = ({ channelName, data }: any) => ({
  type: ActionTypes.PUSH_TO_CHANNEL,
  payload: { channelName, data },
});
