import { IUser } from 'modules/user/constants';

const makeInitials = (user: IUser) => {
  const name = user.name;
  if (!name || !name.trim()) {
    return '?';
  }

  const names = user.name.split(' ');
  if (names.length > 1) {
    const firstName = names[0];
    const lastName = names[names.length - 1];
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  } else {
    if (name.length > 1) {
      return `${name[0].toUpperCase()}${name[1].toLowerCase()}`;
    } else {
      return `${name[0].toUpperCase()}`;
    }
  }
}


const makeColorFromName = (name: string) => {
  if (!name || !name.trim()) {
    return 'black';
  }

  const nums = name.split('').map((z: any) => Number(z.charCodeAt() % 16, 16).toString(16));
  if (nums.length == 6) {
    return '#' + nums.join('');
  } else if (nums.length > 6) {
    return '#' + nums.slice(0, 6).join('');
  } else {
    let soFar = nums;
    while (soFar.length < 6) {
      soFar = soFar.concat(nums);
    }
    return '#' + soFar.slice(0, 6).join('');
  }
}

export const makeIconInputsForUser = (user: IUser) => ({
  initials: makeInitials(user),
  hex: makeColorFromName(user.name),
})