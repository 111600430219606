import * as React from 'react';
import cx from 'classnames';

import Button from '../button.tsx';
import './style.css';


interface ISidebarProps {
  visible: boolean;
  title: string;
  onClose: () => void;
  children: any;
};


const Sidebar = ({
  visible=false,
  title="Sidebar title",
  onClose=() => {},
  children,
}: ISidebarProps) => {
  return (
    <div className={cx("sidebar-slideout--container", { visible })}>
      <header className="sidebar-slideout--header">{title} <div className="sidebar-slideout--close" onClick={onClose}>&times;</div></header>
      <div className="sidebar-slideout--body">
        {children}
      </div>
      <footer className="sidebar-slideout--footer">
        <Button onClick={onClose} type="secondary">Close</Button>
      </footer>
    </div>
  )
};

export default Sidebar;