const root = (state: any) => state.admin;

const presenceRoot = (state: any) => root(state).presence;
export const getRefsToUserId = (state: any) => presenceRoot(state).refsToUserId;
export const getMetaForRef = (state: any, refId: string) => {
  const userId = getRefsToUserId(state)[refId];
  const userMetas = presenceRoot(state).userIdConns[userId];
  const meta = userMetas[refId];
  return meta;
}

const customersPage = (state: any) => root(state).customerPage;
export const getCustomerPagination = (state: any) => customersPage(state).pagination;
export const getCustomerPage = (state: any) => customersPage(state).customers;