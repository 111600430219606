import {
  CurrentGenerationChart as CGC,
  CurrentCarbonIntensityChart as CCIC
} from './number.tsx';

import {
  FuelmixTrend as FMT,
  CarbonIntensityTrend as CIT,
} from './trend.tsx';

import LC from './loading.tsx';

import CFM from './mix.tsx';

export interface IChartProps {
  loading: boolean;
  value: any;
}

export interface ICarbonIntensityTrendProps extends IChartProps {
  region: string;
}

export interface IMixPieChartProps extends IChartProps {
  viewingEmissions: boolean;
}



export const formatNumber = (num: number) => {
  const rounded = Math.round(num);
  if (isNaN(rounded)) {
    return '0';
  }
  return Intl.NumberFormat().format(rounded);
}

export const categoryToColor: any = {
  // green
  storage: '#51C4A1',
  nuclear: '#3BBC95',
  wind: '#25B589',
  hydro: '#10AE7E',
  solar: '#0F9F73',

  // teal
  geothermal: '#6AC2C7',
  batteries: '#5CBCC2',

  // orange
  import: '#EBBC94',
  other: '#E8B387',
  'natural gas': '#E6AB7A',
  'landfill gas': '#E4A36D',
  unknown: '#D09564',
  biogas: '#BB865A',

  // purple
  'multi fuel': '#A271AF',
  oil: '#9467A0',
  'dual fuel': '#855D90',

  // red
  renewables: '#E26D6E',
  'fossil fuel': '#DF5D5E',
  coal: '#DC4D4E',
  refuse: '#C84647',
  biomass: '#B54040',
  wood: '#A13939',
}


const gradients = [
  [4, 171, 121], // green
  [88, 160, 227], // blue
  [221, 72, 72], // red
];

const pickHex = (color1: number[], color2: number[], weight: number): number[] => {
  var p = weight;
  var w = p * 2 - 1;
  var w1 = (w/1+1) / 2;
  var w2 = 1 - w1;
  var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2)];
  return rgb;
};

export const getColorForEmission = (thisEmission: number, maxEmission: number, minEmission: number) => {
  // should be divided by 2 instead of 4,
  // I'm kinda "brown washing" - maybe we should take the median
  const mean = (maxEmission + minEmission) / 4;
  const overMean = thisEmission > mean;
  if (overMean) {
    const blue = gradients[1];
    const red = gradients[2];
    const weight = (thisEmission - mean) / (maxEmission - mean);
    const rgb = pickHex(red, blue, weight);
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  } else {
    const green = gradients[0];
    const blue = gradients[1];
    const weight = thisEmission / mean;
    const rgb = pickHex(blue, green, weight);
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  }
};


export const CurrentGenerationChart = CGC
export const LoadingChart = LC
export const CurrentCarbonIntensityChart = CCIC
export const FuelmixTrend = FMT
export const CarbonIntensityTrend = CIT
export const CurrentFuelMix = CFM