import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { pathOr } from 'ramda';


import {
  IMixPieChartProps,
  LoadingChart,
  categoryToColor,
} from './index.ts';


class CurrentFuelMix extends React.PureComponent<IMixPieChartProps> {
  render () {
    const {
      loading,
      value,
      viewingEmissions,
    } = this.props;

    const title = viewingEmissions ? 'Carbon Emission Mix' : 'Generated Fuel Mix';
    const mwgen = pathOr({}, ['generation'], value);
    let component = <LoadingChart />
    if (!loading) {
      const createSeriesFromRawData = (gen: any) => {
        return Object.entries(gen).map(([fuel, mw]) => {
          const fuelname = fuel.replace('_mw', '').replace('_', ' ');
          let num = Number(mw);
          const fuelcode = fuelname.replace(' ', '_')
          const factor = value.factors[fuelcode] || {value: 0};
          if (viewingEmissions) {
            num = Math.round((factor.value * num) / 12 / 2000);
          }
          return {
            name: fuelname,
            y: num,
            color: categoryToColor[fuelname],
          }
        })
        .filter(({ y }) => y > 0)
        .sort((a, b) => b.y - a.y);
      }

      const createPlotOptions = (series: any) => ({
        chart: {
          style: {
            fontFamily: 'Poppins',
          },
          backgroundColor: 'transparent',
          type: 'pie',
        },
        title: '',
        series: [{
          name: viewingEmissions ? 'CO2 tons in the last 5 minutes' : 'MW generated',
          data: series,
          size: '80%',
          innerSize: '60%',
          marker: {
            enabled: true
          },
        }],
        plotOptions: {
          pie: {
            dataLabels: {
              style: {
                fontSize: '13px'
              }
            }
          }
        },
        tooltip: {
          pointFormat: `<b>{point.y}${viewingEmissions ? ' mTCO<span style="font-variant-position: sub;">2</span>' : ' MW'}</b> ({point.percentage:.1f}%)`
        },
        credits: {
          enabled: false,
        },
      })

      const options = createPlotOptions(createSeriesFromRawData(mwgen));
      component = <HighchartsReact highcharts={Highcharts} options={options} />
    }

    return (
      <div className="carbonara-home-chart--container dynamic-chart">
        <h2>{title}</h2>
        {component}
        {/* <div className="carbon-intensity--legend">
          <div>Carbon emission intensity</div>
          <div className="carbon-intensity-legend--gradient"></div>
          <div className="carbon-intensity-legend--explanation">
            <div>Low</div>
            <div>High</div>
          </div>
        </div> */}
      </div>
    )
  }
}


export default CurrentFuelMix;