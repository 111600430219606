import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import 'react-toggle/style.css'

import userReducer from 'modules/user/reducer.ts';
import userEpic from 'modules/user/epics.ts';
import adminEpic from 'modules/admin/epics.ts';
import socketEpic from 'modules/socket/epics.ts';
import adminReducer from 'modules/admin/reducer.ts';
import reportReducer from 'modules/reports/reducer.ts';

import { DEBUG } from 'constants/resources.ts';

import App from './App.tsx';
import './index.css';


const epicMiddleware = createEpicMiddleware();
const mountSentry = () => {
  if (!DEBUG) {
    Sentry.init({ dsn: 'https://757fe40cda654b4bae3fdad914519417@sentry.io/2494826' });
  }
};
setTimeout(mountSentry, 0);
const compose = composeWithDevTools({ trace: true, traceLimit: 25 });
const loggingMiddleware = () => (next: any) => (action: any) => {
  if (DEBUG) {
    console.info('[Carbonara] applying action', action);
  }
  next(action);
}

const store = createStore(
  combineReducers({
    user: userReducer,
    admin: adminReducer,
    reports: reportReducer,
  }),
  compose(
    applyMiddleware(epicMiddleware),
    applyMiddleware(loggingMiddleware),
  ),
);

epicMiddleware.run(
  combineEpics(
    adminEpic,
    userEpic,
    socketEpic,
  )
)

if (DEBUG) {
  (window as any).store = store;
}


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);