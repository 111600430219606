import mapboxgl from "mapbox-gl";

import baMapData from 'data/geojson/ba_simple_meta.json';
import largeBaMapData from 'data/geojson/custom_ba_simple_meta.json';
import isoMapData from 'data/geojson/iso_simple_meta.json';

import baOverlapData from 'data/geojson/ba_overlap_meta.json';
import largeBaOverlapData from 'data/geojson/custom_ba_overlap_meta.json';
import isoOverlapData from 'data/geojson/iso_overlap_meta.json';


export interface IMapState {
  mapLevel: MapLevel
  mapSelection: Set<string>
  userEnteredSelection: boolean
}


export enum MapLevel {
  BAs = 'All BAs',
  LBAs = 'Large BAs',
  ISOs = 'ISOs',
}


export const rainbowColorMap10 = [
  0, "#9e0142",
  1, "#d53e4f",
  2, "#f46d43",
  3, "#fdae61",
  4, "#fee08b",
  5, "#e6f598",
  6, "#abdda4",
  7, "#66c2a5",
  8, "#3288bd",
  9, "#5e4fa2"
];


const focusedColor = '#343a40';
const selectedColor = '#54b6ac';

export const singularitySelectStyle = {
  option: (provided: any, state: any) => {
    let backgroundColor = 'white';
    if (state.isSelected) {
      backgroundColor = selectedColor;
    } else if (state.isFocused) {
      backgroundColor = focusedColor;
    }
    return {
      ...provided,
      color: (state.isSelected || state.isFocused) ? 'white' : 'black',
      backgroundColor: backgroundColor,
    }
  },
  control: (provided: any) => ({
    ...provided,
    '&:hover': { borderColor: focusedColor }, // border style on hover
    border: '1px solid lightgray', // default border color
    boxShadow: 'none', // no box-shadow
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition, padding: '2px' };
  }
}


export function loadSvgImage(svg: any, onload: any) {
  let img = new Image(42, 42);
  img.onload = () => onload(img);
  const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
  const dataUrl = URL.createObjectURL(blob);
  img.src = dataUrl;
}


// Hacky way of create cross-hatch patterns. We generate a hatch texture for
// every possible pair of colors, and choose from them later. The `callback`
// function is called once all patterns are loaded.
export const generateOverlapColors = (map: mapboxgl.Map, callback: () => void) => {
  const colorMap = [
    "#9e0142", "#d53e4f", "#f46d43", "#fdae61", "#fee08b",
    "#e6f598", "#abdda4", "#66c2a5", "#3284bd", "#5e4fa2"
  ];

  const svgTemplate = `
    <svg width="84" height="84" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="transformedPattern"
            x="0" y="0" width="2" height="20"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)">
          <rect width="2" height="20" x="0" y="0" style="fill: #0000ff; opacity: 0.15" />
          <circle cx="1" cy="1" r="8" style="stroke: none; fill: #000000; opacity: 0.15" />
        </pattern>
      </defs>
      <rect x="0" y="0" width="84" height="84" style="stroke: none; fill: url(#transformedPattern);" />
    </svg>`

  const promises = [];
  for (let i = 0; i < colorMap.length; ++i) {
    for (let j = 0; j < colorMap.length; ++j) {
      promises.push(
        new Promise<void>((resolve, _) => {
          const coloredSvg = svgTemplate.replace('#000000', colorMap[j]).replace('#0000ff', colorMap[i]);
          loadSvgImage(coloredSvg, (img: any) => {
            map.addImage(`pattern-${i}-${j}`, img);
            resolve();
          });
        })
      );
    }
  }

  Promise.all(promises)
    .then(callback)
    .catch(console.error);
}


export const filterMapData = (mapData: any, mapState: IMapState) => {
  let filteredMapData = mapData;
  if (mapState.userEnteredSelection && mapState.mapSelection.size > 0) {
    filteredMapData = {
      type: 'FeatureCollection',
      features: mapData.features.filter((f: any) => mapState.mapSelection.has(f.properties.apiRegionCode))
    };
  }

  // Add a 'selected' tag to the selected features to use for visualization.
  filteredMapData.features = filteredMapData.features.map((f: any) => {
    let f2 = f;
    f2.properties['selected'] = mapState.mapSelection.has(f.properties.apiRegionCode);
    return f2;
  });

  return filteredMapData;
}


// This function decides which GeoJSON layer is shown.
export const getMapData = (mapState: IMapState) => {
  const transform = (mapData: typeof isoMapData): typeof isoMapData => {
    return {
      ...mapData,
      features: mapData.features
    };
  };
  return filterMapData({
    [MapLevel.BAs]: transform((baMapData as typeof isoMapData)),
    [MapLevel.LBAs]: transform(largeBaMapData),
    [MapLevel.ISOs]: transform(isoMapData),
  }[mapState.mapLevel], mapState);
}

export const getMapOverlapData = (mapState: IMapState) => {
  if (mapState.userEnteredSelection) {
    return {
      features: [],
      type: "FeatureCollection"
    };
  }

  if (mapState.mapLevel === MapLevel.BAs) {
    return baOverlapData;
  } else if (mapState.mapLevel === MapLevel.LBAs) {
    return largeBaOverlapData;
  } else if (mapState.mapLevel === MapLevel.ISOs) {
    return isoOverlapData;
  }

  return {
    features: [],
    type: "FeatureCollection"
  };
}