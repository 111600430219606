export enum ActionTypes {
  RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER',
  RECEIVE_CURRENT_CUSTOMER = 'RECEIVE_CURRENT_CUSTOMER',
  RECEIVE_API_USAGE = 'RECEIVE_API_USAGE',
  RECEIVE_TEAMMATES = 'RECEIVE_TEAMMATES',
  UPDATE_ONBOARDING = 'UPDATE_ONBOARDING',
};

export interface IAttribute {
  name: string
  value: unknown
  namespace: string
  type: string
}


export interface IUser {
  name: string;
  email: string;
  customer_id: string;
  role: string;
  id: string;
  status: string;
  attributes: IAttribute[];
}

export interface ICustomer {
  name: string;
  id: string;
  status: string;
  api_key_id: string;
  api_key_value: string;
  api_key_expiration: Date;
  api_key_plan_name: string;
  attributes: IAttribute[];
}

export interface IAPIUsage {
  totalUsed: number;
  totalAllowed: number;
}