export enum ActionTypes {
  RECEIVE_ALL_CUSTOMERS = 'RECEIVE_ALL_CUSTOMERS',
  RECEIVE_PRESENCE_STATE = 'RECEIVE_PRESENCE_STATE',
  RECEIVE_PRESENCE_DIFF = 'RECEIVE_PRESENCE_DIFF',
};


export interface IPresenceMeta {
  phx_ref: string;
  online_at: string;
  href: string;
  language: string;
  referrer: string;
  userAgent: string;
}

export interface IConnectedUser {
  userId: string;
  metas: IPresenceMeta[];
}
