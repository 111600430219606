import * as React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getMapStatus } from 'modules/reports/selectors.ts';


const TWO_DAYS = 1000 * 60 * 60 * 24 * 3;
const formatDate = (date: Date) => date.toLocaleString(window.navigator.language, {month: 'long', day: 'numeric', year: 'numeric'})
const hourFormat = new Intl.DateTimeFormat('default', { hour: 'numeric', timeZone: 'UTC'});


export const MapTimePicker = ({
  selectedTime,
  setSelectedTime,
}: any) => {
  const baseDate = new Date('2023-06-20T12:00:00Z');
  const getStatus = (timestr: string) => useSelector((state) => getMapStatus(state, timestr));

  const dates: any[] = [];

  for (let i = 0; i < 24; i++) {
    const newDate = new Date(baseDate.valueOf());
    newDate.setUTCHours(i, 0, 0, 0);
    dates.push({
      timestr: encodeURIComponent(newDate.toISOString().replace('.000Z', '+00:00')),
      datetime: newDate
    });
  }

  React.useEffect(() => {
    if (selectedTime === undefined) {
      setSelectedTime(dates[0].timestr);
    }
  }, [selectedTime, setSelectedTime])

  return (
    <div>
      <div>{formatDate(baseDate)}</div>
      <div className="us-eia-map-daily--info">Daily updates coming soon</div>
      <div className="us-eia-map-time-picker--help">Select time by clicking the hours (hours are in UTC)</div>
      <div className="us-eia-map-time-picker-times--container">
        {dates.map(date => (
          <div
            key={date.timestr}
            className={cx('us-eia-map-time-picker-times--option', getStatus(date.timestr), { selected: selectedTime === date.timestr })}
            onClick={() => setSelectedTime(date.timestr)}
          >{hourFormat.format(date.datetime)}</div>
        ))}
      </div>
    </div>
  )
}

export default MapTimePicker;