import * as React from 'react';
import { pathOr } from 'ramda';

import { IChartProps, formatNumber } from './index.ts';
import LoadingChart from './loading.tsx';
import { TotalGen } from './trend.tsx';



export const CurrentGenerationChart = ({
  loading,
  value,
  trendValue,
}: IChartProps) => {
  const mwgen = pathOr({}, ['generation'], value);
  const total = formatNumber(Object.values(mwgen).reduce((sofar: any, nextup: any) => sofar + Number(nextup), 0) as number);

  return (
    <div className="carbonara-home-chart--container single-number">
      <h2>Current Total Generation</h2>
      {loading && <LoadingChart />}
      {!loading && <div className="single-number-chart--content">
          <h2 className="carbonara-home-chart--metric">{total} MW</h2>
          <em>right now</em>
        </div>
      }
      {trendValue.gfm && <TotalGen value={trendValue} />}
    </div>
  )
}

export const CurrentCarbonIntensityChart = ({
  loading,
  value,
  trendValue,
}: IChartProps) => {
  const mwgen = pathOr({}, ['generation'], value);
  const totalMwGen: number = Object.values(mwgen).reduce((sofar: any, nextup: any) => sofar + Number(nextup), 0) as number;
  const generated = pathOr(0, ['intensity', 'generated_rate'], value);
  // const marginal = pathOr(0, ['intensity', 'data', 'marignal_rate'], value);
  const genstr = formatNumber(((generated * totalMwGen) / 12) / 2000);
  // const marstr = formatNumber(marginal);
  return (
    <div className="carbonara-home-chart--container single-number">
      <h2>Current Total Carbon</h2>
      {loading && <LoadingChart />}
      {!loading && <div className="single-number-chart--content">
        <h2 className="carbonara-home-chart--metric">{genstr} tons of CO2</h2>
        <em>in the last 5 minutes</em>
      </div>}
      {trendValue.ci && <TotalGen value={trendValue.ci} isCarbon={true} />}
    </div>
  )
}

