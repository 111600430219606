import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import Button from 'components/shared/button.tsx';
import { IUser } from 'modules/user/constants.ts';
import Modal from 'components/shared/modal/index.tsx';
import UserIcon from 'components/shared/user-icon/index.tsx';
import { currentUserIsAdmin } from 'modules/user/selectors.ts';
import Badge from 'components/shared/badge/index.tsx';
import { getInstance } from 'api/index.ts';

import './style.css';


const Roles = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Admin',
    value: 'admin',
  }
]

interface IPartialBadgeProps {
  color: string;
  background: string;
}

interface IStatusBadgeProps {
  [key: string]: IPartialBadgeProps
}

const statusBadgeProps: IStatusBadgeProps = {
  invited: {
    color: '#e3a30a',
    background: 'rgba(255, 228, 83, 0.48)',
  },
  active: {
    color: 'var(--color-green-1)',
    background: 'var(--color-green-5)',
  },
  verified: {
    color: 'var(--color-green-1)',
    background: 'var(--color-green-5)',
  }
}


interface ITeammatesProps {
  team: IUser[];
  isAdmin: boolean;
}

interface IUserRowProps {
  user: IUser;
}


const UserRow = ({
  user
}: IUserRowProps) => {
  return (
    <tr>
      <td><UserIcon user={user} /></td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td><Badge {...statusBadgeProps[user.status]} >{user.status}</Badge></td>
      <td>{user.role}</td>
    </tr>
  )
}

const InviteUserModal = ({
  ...modalProps
}: any) => {

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [selectedRole, setSelectedRole] = React.useState(Roles[0]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const submit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    const lowerCaseEmail = email.toLowerCase();
    getInstance()
      .post('/api/v1/users/invite', {invitations: [{ email: lowerCaseEmail, name, role: selectedRole.value }]})
      .then(({ data }: any) => {
        const invitation = data[0];
        setIsLoading(false);
        if (invitation.success) {
          setSuccess(true);
          setTimeout(() => modalProps.onClose(), 850);
        } else {
          setError(invitation.reason);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setError(err.error_message);
      })
  }

  return (
    <Modal {...modalProps}>
      <form onSubmit={submit} className="invite-user--form">
        <label>
          full name
          <input type="text" value={name} placeholder="My Friend" onChange={e => setName(e.target.value)} />
        </label>
        <label>
          email
          <input type="email" value={email} placeholder="friend@email.com" onChange={e => setEmail(e.target.value)} />
        </label>
        <label>
          role
          <Select options={Roles} value={selectedRole} onChange={setSelectedRole}/>
        </label>
        <Button loading={isLoading} buttonType="submit" onClick={submit}>Submit</Button>
        {error && <div className="invite-user--error">{error}</div>}
        {success && <div className="invite-user--success">Invitation sent successfully!</div>}
      </form>
    </Modal>
  )
}


const Teammates = ({
  team,
  isAdmin,
}: ITeammatesProps) => {
  const [inviteUserModalOpen, setInviteUserModalOpen] = React.useState(false);

  return (
    <div>
      <InviteUserModal isOpen={inviteUserModalOpen} onClose={() => setInviteUserModalOpen(false)} title="Invite a user"/>
      {isAdmin && <Button onClick={() => setInviteUserModalOpen(true)} type="secondary">Invite a new user</Button>}
      <table>
        <thead>
          <tr>
            <th></th>
            <th>name</th>
            <th>email</th>
            <th>status</th>
            <th>role</th>
          </tr>
        </thead>
        <tbody>
          {team.map(user => <UserRow key={user.id} user={user} />)}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  team: state.user.teammates || [],
  isAdmin: currentUserIsAdmin(state),
})

export default connect(mapStateToProps)(Teammates);