import * as React from 'react';

import { getInstance } from "api/index.ts";
import Button from "components/shared/button.tsx";

const API = getInstance();

const SignupSurvey = () => {

  const [roleAtCompany, setRole] = React.useState('');
  const [usageIntention, setUsageIntention] = React.useState('');
  const [attribution, setAttribution] = React.useState('');
  const [attributionOther, setAttributionOther] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (roleAtCompany && usageIntention) {
      setDisabled(false);
    } else if (!disabled) {
      setDisabled(true);
    }
  }, [roleAtCompany, usageIntention]);

  const attributionSubmission = `${attribution}${attribution === "Other" ? `: ${attributionOther}` : ""}`

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    API
      .post(`/api/v1/users/me/signup-survey`, {
        role_at_company: roleAtCompany,
        usage_intention: usageIntention,
        attribution: attributionSubmission
      })
      .then(() => {
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        setError(err.error_message);
      })
  };

  return (
    <div className="auth-signup--form signup-survey">
      <div className="auth-signup-form--content">
        <h3>Almost there!</h3>
        <div>Tell us a little about yourself.</div>
        <form onSubmit={submit}>
          <label>
            What's your role?
            <input value={roleAtCompany} onChange={(e) => setRole(e.target.value)} />
          </label>
          <label>
            How do you plan on using {window.location.hostname.includes("carbonara") ? "Carbonara" : "Singularity's Grid Carbon Dashboard and API"}?
            <textarea rows={2} value={usageIntention} onChange={(e) => setUsageIntention(e.target.value)} />
          </label>
          <label>
            How did you hear about Singularity?
            <select className="attestation-select" value={attribution} onChange={(e) => setAttribution(e.target.value)}>
              <option></option>
              <option >Google Search</option>
              <option >Through a friend</option>
              <option >Press / Blog Article</option>
              <option >Current Customer</option>
              <option >Social Media</option>
              <option >Other</option>
            </select>
            {(attribution == "Other") && (<textarea rows={1} value={attributionOther} onChange={(e) => setAttributionOther(e.target.value)} />)}
          </label>
          <Button className="carbonara-signup-button--confirm" disabled={disabled} buttonType="submit" loading={isLoading} onClick={submit} type="secondary">Continue</Button>
          {error && <div className="carbonara-signup-error">{error}</div>}
        </form>
      </div>
    </div>
  )
};

export default SignupSurvey;