import * as React from 'react';
import cx from 'classnames';

import LoadingDots from 'components/shared/loadingdots.tsx';

import './button.css';

const lightColorTypes = new Set([
  'primary',
  'secondary',
]);


interface IButtonProps {
  type?: "primary" | "secondary" | "tertiary" | "subtle";
  buttonType?: "reset" | "submit" | "button";
  size?: "medium" | "large" | "small" | "jumbo";
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  children?: any;
  onClick?: (event: any) => void;
}


const Button = ({
  type: buttonType = "primary",
  size: buttonSize = "medium",
  buttonType: type = "button",
  loading=false,
  disabled=false,
  children,
  className,
  onClick=(event) => void 0,
  ...props
}: IButtonProps) => (
  <button type={type} onClick={onClick} className={cx('carbonara-btn', buttonSize, buttonType, className, { disabled, loading })} {...props}>
    {loading ? <LoadingDots lightDot={lightColorTypes.has(buttonType)} /> : children}
  </button>
)

export default Button