import * as React from 'react';

import { IPagination } from "modules/admin/actions";

import './style.css';



const Pagination = ({
  pagination,
  onClick,
}: {pagination: IPagination, onClick: (page: number) => void}) => {
  const onClickPrev = () => {
    if (pagination.prev) {
      onClick(pagination.prev);
    }
  };

  const onClickNext = () => {
    if (pagination.next) {
      onClick(pagination.next);
    }
  }

  const beforePages = [];
  const afterPages = [];

  if (pagination.this !== pagination.first) {
    if (pagination.this - 1 > pagination.first) {
      beforePages.push(pagination.this - 1);
    }

    if (pagination.this - 2 > pagination.first) {
      beforePages.push(pagination.this - 2);
    }
  }

  if (pagination.this !== pagination.last) {
    if (pagination.this + 1 < pagination.last) {
      afterPages.push(pagination.this + 1);
    }

    if (pagination.this + 2 < pagination.last) {
      afterPages.push(pagination.this + 2);
    }
  }

  const showBeforeDots = beforePages.length === 2 && pagination.this - 3 > pagination.first;
  const showAfterDots = afterPages.length === 2 && pagination.this + 3 < pagination.last;

  return <div className="pagination--container">
    <div className="pagination--button" onClick={onClickPrev}>&lt;</div>
    {pagination.first !== pagination.this && <div className="pagination--button" onClick={() => onClick(pagination.first)}>{pagination.first}</div>}
    {showBeforeDots && <div className="pagination--dots">...</div>}
    {beforePages.map(i => <div className="pagination--button" key={i} onClick={() => onClick(i)}>{i}</div>)}
    <div className="pagination--button disabled">{pagination.this}</div>
    {afterPages.map(i => <div key={i} className="pagination--button" onClick={() => onClick(i)}>{i}</div>)}
    {showAfterDots && <div className="pagination--dots">...</div>}
    {pagination.last !== pagination.this && <div className="pagination--button" onClick={() => onClick(pagination.last)}>{pagination.last}</div>}
    <div className="pagination--button" onClick={onClickNext}>&gt;</div>
  </div>;
};

export default Pagination;