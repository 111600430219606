import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import APISettings from 'components/api-settings/index.tsx';
import Dropdown from 'components/shared/dropdown.tsx';
import Teammates from 'components/teammates/index.tsx';
import { currentUserIsSystemAdmin } from 'modules/user/selectors.ts';

import './settings.css';

interface IStringMap {
  [key: string]: string;
}

const settingsNames: IStringMap = {
  '/settings/api': 'API management',
  '/settings/team': 'Teammates',
}

interface ISettingsLayoutProps {
  location: any;
  history: any;
}


const SettingsLayout = ({
  location,
  history,
}: ISettingsLayoutProps) => {
  const isSystemAdmin = useSelector(currentUserIsSystemAdmin);
  if (isSystemAdmin) {
    settingsNames['/settings/create-customer'] = 'Create customer';
  }

  const settingsName = settingsNames[location.pathname];

  return (
    <div className="carbonara-settings-menu--container">
      <div className="carbonara-settings-nav--container">
        <Dropdown
          uniqueId='settings'
          viewText={<h6>Settings / <span className="selected-region--name">{settingsName} <FontAwesomeIcon icon={faAngleDown} /></span></h6>}
          onSelect={({ value }) => history.push(value)}
          options={Object.entries(settingsNames).map(([value, label]) => ({ value, label }))}
        />
        <h3>{settingsName}</h3>
      </div>
      <Switch>
        <Route path="/settings/api" component={APISettings} />
        <Route path="/settings/team" component={Teammates} />
      </Switch>
    </div>
  )
}


export default withRouter(SettingsLayout)