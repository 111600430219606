import produce from 'immer';

import { ActionTypes, IConnectedUser, IPresenceMeta } from 'modules/admin/constants.ts';
import { IConnectedChange } from 'modules/admin/actions.ts';
import { ICustomer } from 'modules/user/constants.ts';
import { IPagination } from './actions';


interface IRefToMeta {
  [key: string]: IPresenceMeta
}

interface IUserToRefs {
  [key: string]: IRefToMeta
};
interface IStringMap {
  [key: string]: any
};

interface IState {
  customerPage: {
    customers: ICustomer[] | null;
    pagination: IPagination | null;
  },
  presence: {
    userIdConns: IUserToRefs,
    refsToUserId: IStringMap,
  },
}


const defaultState: IState = {
  customerPage: {
    customers: null,
    pagination: null,
  },
  presence: {
    userIdConns: {},
    refsToUserId: {}
  },
};


export interface IPresencePayload {
  presence: IConnectedUser[]
}

interface IConnChangePayload {
  connectionChange: IConnectedChange
}

const receiveAllCustomers = (newState: IState, { customers, pagination }: any) => {
  newState.customerPage.customers = customers;
  newState.customerPage.pagination = pagination;
  return newState;
}

const receivePresenceState = (newState: IState, { presence }: IPresencePayload) => {
  const refsToUserIds: any = {};
  const userIdConns: any = {};

  presence.forEach((pres: IConnectedUser) => {
    userIdConns[pres.userId] = {};
    pres.metas.forEach(p => {
      refsToUserIds[p.phx_ref] = pres.userId;
      userIdConns[pres.userId][p.phx_ref] = p;
    });
  });

  newState.presence.refsToUserId = refsToUserIds;
  newState.presence.userIdConns = userIdConns;
  return newState;
}

const receivePresenceDiff = (newState: IState, { connectionChange }: IConnChangePayload) => {
  connectionChange.leaves.forEach(connUser => {
    connUser.metas.forEach(meta => {
      delete newState.presence.userIdConns[connUser.userId][meta.phx_ref];
      delete newState.presence.refsToUserId[meta.phx_ref];
    })
  });

  connectionChange.joins.forEach((pres: IConnectedUser) => {
    pres.metas.forEach(p => {
      newState.presence.refsToUserId[p.phx_ref] = pres.userId;
      const userDataExists = !!newState.presence.userIdConns[pres.userId];
      if (!userDataExists) {
        newState.presence.userIdConns[pres.userId] = {};
      }
      newState.presence.userIdConns[pres.userId][p.phx_ref] = p;
    });
  })
  return newState;
}


export default (state = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_ALL_CUSTOMERS:
      return produce(state, (draftState: any) => receiveAllCustomers(draftState, action.payload));
    case ActionTypes.RECEIVE_PRESENCE_STATE:
      return produce(state, (draftState: any) => receivePresenceState(draftState, action.payload));
    case ActionTypes.RECEIVE_PRESENCE_DIFF:
      return produce(state, (draftState: any) => receivePresenceDiff(draftState, action.payload));
    default:
      return state;
  }
}