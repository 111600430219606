// credit where it's due: https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php
const asc = (arr: number[]) => arr.sort((a, b) => a - b);

const quantile = (arr: number[], q: number) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

export const q20 = (arr: number[]) => quantile(arr, .2);
export const q40 = (arr: number[]) => quantile(arr, .4);
export const q60 = (arr: number[]) => quantile(arr, .6);
export const q80 = (arr: number[]) => quantile(arr, .8);

export const normalize = (
  value: number,
  seriesMax: number,
  seriesMin: number,
  ceiling: number,
  floor: number
) => {
  const diff: number = seriesMax - seriesMin;
  const normalized = ((value * (ceiling - floor)) / diff) + floor;
  return normalized;
};
