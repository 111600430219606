import produce from 'immer';

import { ActionTypes } from 'modules/reports/constants.ts';
import { IReportAction } from 'modules/reports/actions.ts';

interface IState {
  homeData: any;
  carbonTrendData: any;
  hourlyCarbonTrendData: any;
  fuelmixTrendData: any;
  hourlyFuelmixTrendData: any;
  eiaUSMap: any;
  mapStatuses: any;
}


const defaultState: IState = {
  homeData: {},
  carbonTrendData: {},
  hourlyCarbonTrendData: {},
  fuelmixTrendData: {},
  hourlyFuelmixTrendData: {},
  eiaUSMap: {},
  mapStatuses: {},
};

const receiveRegionHomeData = (newState: IState, { region, data }: any) => {
  newState.homeData[region] = {
    lastFetched: Date.now(),
    data,
  };
  return newState;
}

const receiveRegionCarbonTrendData = (newState: IState, { region, data }: any) => {
  newState.carbonTrendData[region] = {
    lastFetched: Date.now(),
    data,
  };
  return newState;
}

const receiveRegionHourlyCarbonTrendData = (newState: IState, { region, data }: any) => {
  newState.hourlyCarbonTrendData[region] = {
    lastFetched: Date.now(),
    data,
  };
  return newState;
}

const receiveRegionFuelmixTrendData = (newState: IState, { region, data }: any) => {
  newState.fuelmixTrendData[region] = {
    lastFetched: Date.now(),
    data,
  };
  return newState;
}

const receiveRegionHourlyFuelmixTrendData = (newState: IState, { region, data }: any) => {
  newState.hourlyFuelmixTrendData[region] = {
    lastFetched: Date.now(),
    data,
  };
  return newState;
}

const receiveEIAUSMap = (newState: IState, { timestr, data }: any) => {
  newState.eiaUSMap[timestr] = data;
  return newState;
}

const receiveMapStatus = (newState: IState, { timestr, status }: any) => {
  newState.mapStatuses[timestr] = status;
  return newState;
}

export default (state = defaultState, action: IReportAction) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_REGION_HOME_DATA:
      return produce(state, (draftState: any) => receiveRegionHomeData(draftState, action.payload));
    case ActionTypes.RECEIVE_REGION_CARBON_TREND_DATA:
      return produce(state, (draftState: any) => receiveRegionCarbonTrendData(draftState, action.payload));
    case ActionTypes.RECEIVE_REGION_HOURLY_CARBON_TREND_DATA:
      return produce(state, (draftState: any) => receiveRegionHourlyCarbonTrendData(draftState, action.payload));
    case ActionTypes.RECEIVE_REGION_FUELMIX_TREND_DATA:
      return produce(state, (draftState: any) => receiveRegionFuelmixTrendData(draftState, action.payload));
    case ActionTypes.RECEIVE_REGION_HOURLY_FUELMIX_TREND_DATA:
      return produce(state, (draftState: any) => receiveRegionHourlyFuelmixTrendData(draftState, action.payload));
    case ActionTypes.RECEIVE_EIA_US_MAP:
      return produce(state, (draftState: any) => receiveEIAUSMap(draftState, action.payload));
    case ActionTypes.RECEIVE_MAP_STATUS:
      return produce(state, (draftState: any) => receiveMapStatus(draftState, action.payload));
    default:
      return state;
  }
};