import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import './dropdown.css';


export interface IOption {
  value: string;
  label: string;
}


interface IDropdownProps {
  uniqueId: string;
  viewText: React.ReactElement;
  options: IOption[];
  onSelect: (option: IOption) => void;
}


const Dropdown = ({
  uniqueId,
  viewText,
  options,
  onSelect,
}: IDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const choose = (option: IOption) => {
    setIsOpen(false);
    onSelect(option);
  }
  const toggle = () => setIsOpen(!isOpen);
  (Dropdown as any)['handleClickOutside_' + uniqueId] = () => setIsOpen(false);

  return (
    <div className="carbonara-dropdown--container">
      <div className="carbonara-dropdown--text" onClick={toggle}>{viewText}</div>
      {isOpen && <div className="carbonara-dropdown-options--container">
        {Object.values(options).map(({
          label, value
        }) => (
          <div key={value} className="carbonara-dropdown-option--container" onClick={() => choose({ label, value })}>{label}</div>
        ))}
      </div>}
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: ({ props }: any) => (Dropdown as any)['handleClickOutside_' + props.uniqueId]
};

export default onClickOutside(Dropdown, clickOutsideConfig);