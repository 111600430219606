import * as Sentry from '@sentry/browser';
import cx from 'classnames';
import { omit } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';

import { bootToLogin, getInstance } from 'api/index.ts';
import Admin from 'components/admin/index.tsx';
import Signup from 'components/auth/signup/index.tsx';
import RegionSelect from 'components/auth/signup/region-select';
import SignupSurvey from 'components/auth/signup/survey.tsx';
import Home from 'components/home/index.tsx';
import TopNav from 'components/nav/index.tsx';
import LoadingDots from 'components/shared/loadingdots.tsx';
import Status from 'components/status/index.tsx';
import VerifyEmail from 'components/verify/index.tsx';
import WebhooksRedirect from 'components/webhooks/index.tsx';
import { DEBUG } from 'constants/resources.ts';
import MapLayout from 'layouts/Map/index.tsx';
import Settings from 'layouts/Settings.tsx';
import { receiveCurrentCustomer, receiveCurrentUser, receiveTeammates } from 'modules/user/actions.ts';
import { ICustomer, IUser } from 'modules/user/constants.ts';

import './App.css';


const API = getInstance();


interface IAppState {
  bootstrapDone: boolean;
  currentUser: IUser;
  currentCustomer: ICustomer;
}

interface IAppProps {
  receiveCurrentUser: (user: IUser) => void;
  receiveCurrentCustomer: (customer: ICustomer) => void;
  receiveTeammates: (users: IUser[]) => void;
}

const shouldRenderOnboardingForm = (user: IUser) => {
  return user.status === 'unverified' && !user.attributes.find(attr => attr.name === 'role_at_company')
}

const shouldRenderRegionSelect = (user: IUser, customer: ICustomer) => {
  return !window.location.hostname.includes('carbonara') && user.role === 'owner' && !customer.attributes.find(attr => attr.name === 'api_regions');
}


class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
      bootstrapDone: false,
      currentUser: null,
      currentCustomer: null,
    };
  }

  componentDidMount() {
    this.bootstrap();
    if (!DEBUG) {
      this.mountDrift();
    }
  }

  mountDrift = () => {
    ((): any => {
      var t = (window as any).driftt = (window as any).drift = (window as any).driftt || [];
      if (!t.init) {
        if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
        t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
        t.factory = function(e: any) {
          return function() {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }, t.methods.forEach(function(e: any) {
          t[e] = t.factory(e);
        }), t.load = function(t: string) {
          var e = 3e5, n = Math.ceil((new Date() as any) / e) * e, o = document.createElement("script");
          o.type = "text/javascript", o.async = !0, o.crossOrigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        };
      }
    })();
    (window as any).drift.SNIPPET_VERSION = '0.3.1';
    (window as any).drift.load('2b7p8yk2aw3x');
  }

  bootstrap = async () => {
    await Promise.all([
      API.get('/api/v1/users/me'),
      API.get('/api/v1/customers/me'),
      API.get('/api/v1/customers/me/users'),
    ])
    .then(([
      meUserResponse,
      meCustomerResponse,
      myCustomerUsersResponse,
    ]) => {
      this.handleGetMeSuccess(meUserResponse)
      this.handleGetCustomerSuccess(meCustomerResponse)
      this.handleGetTeammatesSuccess(myCustomerUsersResponse)
    })
    .catch(() => bootToLogin())

    this.setState({
      bootstrapDone: true,
    });
  }

  handleGetCustomerSuccess = (res: any) => {
    const customer = res.data.customer;
    this.setState({
      currentCustomer: customer,
    });
    this.props.receiveCurrentCustomer(customer);
  }

  handleGetMeSuccess = (res: any) => {
    const user = res.data.user;
    this.setState({
      currentUser: user,
    });
    if (!DEBUG) {
      const userAttrs = omit(['attributes', user]);
      (window as any).drift.identify(user.id, userAttrs);
      Sentry.configureScope(function(scope) {
        scope.setUser({ ...user });
      });
    }
    this.props.receiveCurrentUser(user);
  }

  handleGetTeammatesSuccess = (res: any) => {
    const users = res.data.users;
    this.props.receiveTeammates(users);
  }

  makeLoginRequiredComponent(AuthedComponent: React.FunctionComponent) {
    const {
      bootstrapDone,
      currentUser,
      currentCustomer
    } = this.state;

    return () => {
      if (!bootstrapDone) {
        return <div className="app-bootstrap-loading--container">Loading <LoadingDots /></div>;
      }

      if (currentUser) {
        Sentry.configureScope((scope) => {
          scope.setUser(currentUser);
        });

        if (shouldRenderOnboardingForm(currentUser)) {
          return <SignupSurvey />;
        }

        if (shouldRenderRegionSelect(currentUser, currentCustomer)) {
          return <RegionSelect canSkip={true}/>;
        }

        return <AuthedComponent />;
      } else {
        bootToLogin();
      }
    }
  }

  render() {

    const {
      currentUser,
    } = this.state;

    return (
      <BrowserRouter basename="/app">
        <TopNav />
        <div className={cx("carbonara-app-content--container", { 'no-sidebar': !currentUser })}>
          <Route exact={true} path="/" render={() => <Redirect to="/home" />} />
          <Route path="/home" render={this.makeLoginRequiredComponent(Home)} />
          <Route path="/verify" component={VerifyEmail} />
          <Route path="/signup" component={Signup}/>
          <Route path="/settings" component={this.makeLoginRequiredComponent(Settings as any)}/>
          <Route path="/admin" component={this.makeLoginRequiredComponent(Admin)} />
          <Route path="/map" component={this.makeLoginRequiredComponent(MapLayout as any)} />
          <Route path="/status" component={Status}/>
          <Route path="/webhooks" component={this.makeLoginRequiredComponent(WebhooksRedirect as any)} />
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  receiveCurrentUser: (user: IUser) => dispatch(receiveCurrentUser(user)),
  receiveCurrentCustomer: (customer: ICustomer) => dispatch(receiveCurrentCustomer(customer)),
  receiveTeammates: (users: IUser[]) => dispatch(receiveTeammates(users)),
})

export default connect(null, mapDispatchToProps)(App);