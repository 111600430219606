import { ActionTypes } from 'modules/reports/constants.ts';


export interface IReportAction {
  type: ActionTypes;
  payload: IReceiveRegionHomeReportPayload;
}


interface IReceiveRegionHomeReportPayload {
  data: any;
  region: string;
}

export const receiveRegionHomeReport = (region: string, data: any) => ({
  type: ActionTypes.RECEIVE_REGION_HOME_DATA,
  payload: { region, data }
});

export const receiveRegionCarbonTrend = (region: string, data: any) => ({
  type: ActionTypes.RECEIVE_REGION_CARBON_TREND_DATA,
  payload: { region, data }
});

export const receiveRegionHourlyCarbonTrend = (region: string, data: any) => ({
  type: ActionTypes.RECEIVE_REGION_HOURLY_CARBON_TREND_DATA,
  payload: { region, data }
});

export const receiveRegionFuelmixTrend = (region: string, data: any) => ({
  type: ActionTypes.RECEIVE_REGION_FUELMIX_TREND_DATA,
  payload: { region, data }
});

export const receiveRegionHourlyFuelmixTrend = (region: string, data: any) => ({
  type: ActionTypes.RECEIVE_REGION_HOURLY_FUELMIX_TREND_DATA,
  payload: { region, data }
});

export const receiveEIAUSMap = (timestr: string, data: any) => ({
  type: ActionTypes.RECEIVE_EIA_US_MAP,
  payload: { timestr, data }
});

export const receiveMapStatus = (timestr: string, status: any) => ({
  type: ActionTypes.RECEIVE_MAP_STATUS,
  payload: { timestr, status }
});
