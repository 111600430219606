import * as React from 'react';
import cx from 'classnames';

interface ILoadingDotsProps {
  className?: string;
  lightDot?: boolean;
  isStatic?: boolean;
}

const LoadingDots = ({ className, lightDot = false, isStatic = false }: ILoadingDotsProps) => {
  const dots = [0, 1, 2].map((n) => (
    <div key={n}
         className={cx("loading-dot", { light: lightDot, static: isStatic })}
         style={{ animationDelay: `${n * .2}s`}}></div>
  ))

  return (
    <div className={cx('loading-dots', className)}>
      { dots }
    </div>
  )
}

export default LoadingDots