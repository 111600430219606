export enum ActionTypes {
  JOIN_CHANNEL = 'JOIN_CHANNEL',
  JOIN_CHANNEL_PENDING = 'JOIN_CHANNEL_PENDING',
  JOIN_CHANNEL_SUCCESS = 'JOIN_CHANNEL_SUCCESS',
  JOIN_CHANNEL_FAILED = 'JOIN_CHANNEL_FAILED',

  LEAVE_CHANNEL = 'LEAVE_CHANNEL',
  LEAVE_CHANNEL_PENDING = 'LEAVE_CHANNEL_PENDING',
  LEAVE_CHANNEL_SUCCESS = 'LEAVE_CHANNEL_SUCCESS',
  LEAVE_CHANNEL_FAILED = 'LEAVE_CHANNEL_FAILED',

  PUSH_TO_CHANNEL = 'PUSH_TO_CHANNEL',
  RECEIVE_CHANNEL_MESSAGE = 'RECEIVE_CHANNEL_MESSAGE',
};
