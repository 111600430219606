import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/shared/button.tsx';
import Pagination from 'components/pagination/index.tsx';
import { getInstance } from 'api/index.ts';
import { ICustomer } from 'modules/user/constants.ts';
import { currentUserIsSystemAdmin } from 'modules/user/selectors.ts';
import { IPagination, receiveAllCustomers } from 'modules/admin/actions.ts';
import { getCustomerPagination, getCustomerPage } from 'modules/admin/selectors.ts';


import './style.css';


const API = getInstance();


const Admin = () => {
  const history = useHistory();
  const pagination = useSelector(getCustomerPagination);
  const customers = useSelector(getCustomerPage);
  const isAllowed = useSelector(currentUserIsSystemAdmin);
  const currentUser = useSelector((s: any) => s.user.currentUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [loadingFailed, setLoadingFailed] = React.useState(false);
  const [error, setError] = React.useState('');

  if (!isAllowed) {
    history.push('/home');
  }

  const onPageChange = async (toPage: number) => {
    const params = new URLSearchParams({ page: `${toPage}`, per_page: '50' });
    try {
      const data: {data: ICustomer[], meta: {pagination: IPagination}} = await API.get(`/api/v1/customers?${params}`);
      dispatch(receiveAllCustomers(data.data, data.meta.pagination));
    } catch (err) {
      console.warn(err);
      setLoadingFailed(true);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    onPageChange(1);
  }, []);

  const changeToCustomer = async (customer_id: string) => {
    API
      .patch(`/api/v1/users/${currentUser.id}`, { user: { customer_id }})
      .then(() => {
        window.location.reload();
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setError(err.error_message);
      })

  }

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {((customers || []) as ICustomer[]).map((customer: ICustomer) => (
            <tr key={customer.id}>
              <td>{customer.id}</td>
              <td>{customer.name}</td>
              <td>{customer.status}</td>
              <td><Button disabled={currentUser.customer_id === customer.id} onClick={() => changeToCustomer(customer.id)}>Impersonate</Button></td>
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && <Pagination pagination={pagination} onClick={onPageChange} />}
      {loading && <div>loading</div>}
      {loadingFailed && <div>failed</div>}
    </div>
  )
}

export default Admin;