import { ofType, combineEpics } from 'redux-observable';
import { flatMap, catchError, pluck, map } from 'rxjs/operators';
import { from, empty } from 'rxjs';

import { ActionTypes as UserActionTypes } from 'modules/user/constants.ts';
import { receiveCurrentUser } from 'modules/user/actions.ts';
import { getInstance } from 'api/index.ts';

const API = getInstance();

const updateOnboarding$ = (action$: any) => action$.pipe(
  ofType(UserActionTypes.UPDATE_ONBOARDING),
  pluck('payload'),
  flatMap(({ onboarding }) =>
    from(API.patch('/api/v1/users/me/onboarding', { onboarding }))
      .pipe(
        map(({ data }: any) => receiveCurrentUser(data.user)),
        catchError((err: any) => {
          console.warn(err);
          return empty();
        }),
      )
  )
);


export default combineEpics(
  updateOnboarding$,
)