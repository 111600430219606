import * as React from 'react';
import Toggle from 'react-toggle';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

import {
  IChartProps,
  LoadingChart,
  ICarbonIntensityTrendProps,
  categoryToColor,
} from './index.ts';


Highcharts.setOptions({
  lang: {
    numericSymbols: null,
  }
});


export const FuelmixTrend = ({
  loading,
  value,
  hourly = false
}: IChartProps) => {
  if (loading) {
    return (
      <div className="carbonara-home-chart--container dynamic-chart">
        <h2>{hourly ? 'Hourly ' : ''}Fuelmix Trend</h2>
        <LoadingChart />
        <label className="carbon-emissions-switch invisible">
          <Toggle
            checked={false}
            disabled={true}
            onChange={(t) => (t.target.checked)} />
          <span className="carbon-emissions-switch--label">Show marginal carbon trend</span>
        </label>
        <div className="carbonara-home-chart-forecast-note">Forecast updated every {hourly ? '1 hour' : '5 minutes'}</div>
      </div>
    )
  }

  const allSeries: any = {};
  const maxForGfmSeries: any = {};

  value.gfm.forEach((d: any) => {
    const xValue = new Date(d.datetime).valueOf();
    Object
      .entries(d.data)
      .filter(([fuel, _]) => fuel.endsWith('_mw'))
      .forEach(([fuel, gen]) => {
        if (!maxForGfmSeries[fuel]) {
          maxForGfmSeries[fuel] = xValue;
        } else if (maxForGfmSeries[fuel] < xValue) {
          maxForGfmSeries[fuel] = xValue;
        }

        if (!allSeries[fuel]) {
          allSeries[fuel] = [{ y: Number(gen), x: xValue, fuel }];
        } else {
          allSeries[fuel].push({ y: Number(gen), x: xValue, fuel });
        }
    })
  });

  value['forecast.gfm'].forEach((d: any) => {
    const xValue = new Date(d.datetime).valueOf();
    Object
      .entries(d.data)
      .filter(([fuel, _]) => {
        if (!fuel.endsWith('_mw')) {
          return false;
        }
        if (hourly) {
          return true;
        }
        return xValue > maxForGfmSeries[fuel];
      })
      .forEach(([fuel, gen]) => {
        const entry = `${fuel}forecast`;
        if (!allSeries[entry]) {
          allSeries[entry] = [{ y: Math.round(Number(gen)), x: xValue, fuel }];
        } else {
          allSeries[entry].push({ y: Math.round(Number(gen)), x: xValue, fuel });
        }
      })
  });

  const chartSeries = Object.entries(allSeries).map(([fuel, fuelData]) => {
    const isForecast = fuel.indexOf('forecast') !== -1;

    const fuelcode = fuel.replace('_mw', '').replace('forecast', ' forecast');

    return {
      data: fuelData,
      type: hourly ? 'line' : 'spline',
      step: hourly ? 'start' : undefined,
      marker: {
        enabled: false,
      },
      name: fuelcode.replace('_', ' '),
      color: categoryToColor[fuelcode.replace('_', ' ').replace(' forecast', '')],
      showInLegend: !isForecast,
      dashStyle: isForecast ? 'Dash' : 'Solid',
    };
  });

  const plotOptions: any = {
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        }
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        const date = new Date(this.x);
        return `
          <div>
            <div>${date.toLocaleTimeString(window.navigator.language, {minute: '2-digit', hour: '2-digit'})}</div>
            <div>${this.y}MW from ${this.point.fuel.replace('_mw', '').replace('_', ' ')}</div>
          </div>
        `
      }
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        y: 25,
        formatter: ({ value }: any) => new Date(value).toLocaleTimeString(window.navigator.language, {minute: '2-digit', hour: '2-digit'}),
      },
      tickInterval: 3600 * 1000,
      plotLines: [{
        color: 'var(--color-blue-3)',
        label: {
          text: 'Now',
        },
        dashStyle: 'ShortDash',
        value: Date.now()
      }],
    },
    yAxis: {
      title: {
        text: 'MW Generated',
      },
      min: 0,
    },
    legend: {
      enabled: true,
    },
    title: {
      text: '',
    },
    chart: {
      style: {
        fontFamily: 'Poppins',
      },
      backgroundColor: 'transparent',
      type: hourly ? 'line' : 'spline'
    },
    credits: {
      enabled: false,
    },
    series: chartSeries,
  };

  return (
    <div className="carbonara-home-chart--container dynamic-chart">
      <h2>{hourly ? 'Hourly ' : ''}Generated Fuels Trend</h2>
      <HighchartsReact highcharts={Highcharts} options={plotOptions} />
      <label className="carbon-emissions-switch invisible">
        <Toggle
          checked={false}
          disabled={true}
          onChange={(t) => (t.target.checked)} />
        <span>Show marginal carbon intensity trend</span>
      </label>
      <div className="carbonara-home-chart-forecast-note">Forecast updated every {hourly ? '1 hour' : '5 minutes'}</div>
    </div>
  )
}

export const CarbonIntensityTrend = ({
  loading,
  value,
  region,
  hourly = false
}: ICarbonIntensityTrendProps) => {
  const [viewingMarginal, setViewingMarginal] = React.useState(false);
  const marginalToggleDisabled = region !== 'ISONE';

  if (marginalToggleDisabled && viewingMarginal) {
    setViewingMarginal(false);
  }

  if (loading) {
    return (
      <div className="carbonara-home-chart--container dynamic-chart">
        <h2>{hourly ? 'Hourly ' : ''}Carbon Intensity Trend</h2>
        <LoadingChart />
        <label className="carbon-emissions-switch">
          <Toggle
            checked={viewingMarginal}
            disabled={marginalToggleDisabled}
            onChange={(t) => setViewingMarginal(t.target.checked)} />
          {marginalToggleDisabled && <div className="carbon-emission-switch--disabled">Not available for this region</div>}
          <span className="carbon-emissions-switch--label">Show marginal carbon trend</span>
        </label>
        <div className="carbonara-home-chart-forecast-note">Forecast updated every {hourly ? '1 hour' : '5 minutes'}</div>
      </div>
    )
  }

  const ci_series = ((value.ci.ci || []).filter((d: any) => viewingMarginal ? d.data.marginal_rate : d.data.generated_rate) || []).map((d: any) => ({
    x: new Date(d.datetime).valueOf(),
    y: viewingMarginal ? d.data.marginal_rate : d.data.generated_rate,
  }));
  const forecasted: any = {};
  (value.ci['forecast.ci'] || []).map((d: any) => ({
    x: new Date(d.datetime).valueOf(),
    y: viewingMarginal ? d.data.marginal_rate : d.data.generated_rate,
    horizon: d.data.forecast_horizon_in_minutes,
  })).forEach((d: any) => {
    if (!forecasted[d.x]) {
      forecasted[d.x] = d;
    } else {
      const currentHorizon = d.horizon;
      const storedHorizon = forecasted[d.x].horizon;
      if (currentHorizon < storedHorizon) {
        forecasted[d.x] = d
      }
    }
  });
  value.forecasted_intensity.map((d: any) => ({
    x: new Date(d.start_date).valueOf(),
    y: viewingMarginal ? d.data.marginal_rate : d.data.generated_rate,
    horizon: d.data.forecast_horizon_in_minutes,
  })).forEach((d: any) => {
    if (!forecasted[d.x]) {
      forecasted[d.x] = d;
    } else {
      const currentHorizon = d.horizon;
      const storedHorizon = forecasted[d.x].horizon;
      if (currentHorizon < storedHorizon) {
        forecasted[d.x] = d
      }
    }
  });
  const min_fci_series = Object.values(forecasted);

  const series = [{
    name: 'Actual',
    type: viewingMarginal || hourly ? 'line' : 'spline',
    marker: {
      enabled: false,
    },
    step: viewingMarginal || hourly ? 'start' : undefined,
    color: 'var(--color-blue-3)',
    data: ci_series.sort((a: any, b: any) => a.x - b.x),
  }]

  if (region !== 'IESO') {
    series.push({
      name: 'Forecasted',
      type: viewingMarginal || hourly ? 'line' : 'spline',
      step: viewingMarginal || hourly ? 'start' : undefined,
      color: 'var(--color-grey-4)',
      dashStyle: 'Dash',
      marker: {
        enabled: false,
      },
      data: min_fci_series.sort((a: any, b: any) => a.x - b.x),
    });
  }

  const plotOptions = {
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        }
      },
      line: {
        marker: {
          enabled: false,
        }
      }
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        const date = new Date(this.x);
        return `
          <div>
            <div>${date.toLocaleTimeString(window.navigator.language, {minute: '2-digit', hour: '2-digit'})}</div>
            <div>${this.point.series.name} carbon: ${Math.round(this.y)}lbs/MWh </div>
          </div>
        `
      }
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        y: 25,
        formatter: ({ value }: any) => new Date(value).toLocaleTimeString(window.navigator.language, {minute: '2-digit', hour: '2-digit'}),
      },
      tickInterval: 3600 * 1000,
      plotLines: [{
        color: 'var(--color-blue-3)',
        label: {
          text: 'Now',
        },
        dashStyle: 'ShortDash',
        value: Date.now()
      }],
    },
    yAxis: {
      title: {
        text: 'Carbon Intensity (lbs/MWh)',
      },
    },
    legend: {
      enabled: true,
    },
    title: {
      text: '',
    },
    chart: {
      style: {
        fontFamily: 'Poppins',
      },
      backgroundColor: 'transparent',
      type: viewingMarginal || hourly ? 'line' : 'spline',
    },
    credits: {
      enabled: false,
    },
    series,
  };

  return (
    <div className="carbonara-home-chart--container dynamic-chart">
      <h2>{hourly ? 'Hourly ' : ''}Carbon Intensity Trend</h2>
      <HighchartsReact highcharts={Highcharts} options={plotOptions} />
      <label className="carbon-emissions-switch">
        <Toggle
          checked={viewingMarginal}
          disabled={marginalToggleDisabled}
          onChange={(t) => setViewingMarginal(t.target.checked)} />
        <span className="carbon-emissions-switch--label">Show marginal carbon trend</span>
        {marginalToggleDisabled && <div className="carbon-emission-switch--disabled">Not available for this region</div>}
      </label>
      <div className="carbonara-home-chart-forecast-note">Forecast updated every {hourly ? '1 hour' : '5 minutes'}</div>
    </div>
  )
}


export const TotalGen = ({
  value,
  isCarbon = false,
}: any) => {
  let minY: any = 1000000;
  let maxY: any = 0;
  let points = [];
  if (isCarbon) {
    points = (value.ci || []).map((d: any) => {
      const x = new Date(d.datetime).valueOf();
      const y = d.data.generated_rate;

      if (y < minY) {
        minY = y;
      }

      if (y > maxY) {
        maxY = y;
      }

      return {
        x: x,
        y: y,
      };

    });
  } else {
    points = (value.gfm || []).map((d: any) => {
      const x = new Date(d.datetime).valueOf();
      const y = Object
        .values(d.data)
        .reduce((prev: number, curr: any) => prev + Number(curr), 0);

      if (y < minY) {
        minY = y;
      }

      if (y > maxY) {
        maxY = y;
      }

      return {
        x: x,
        y: y,
      };
    });
  }

  const series = {
    data: points,
    type: 'areaspline',
    marker: {
      enabled: false,
    },
    margin: 0,
    spacing: [0, 0, 0, 0],
    height: 140,
  };

  const options: any = {
    plotOptions: {
      areaspline: {
        fillOpacity: 0.15,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            }
          }
        }
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      startOnTick: false,
      min: minY * 0.99,
      max: maxY,
    },
    title: {
      text: null,
    },
    chart: {
      style: {
        fontFamily: 'Poppins',
      },
      backgroundColor: 'transparent',
      type: 'areaspline',
      height: 120,
    },
    credits: {
      enabled: false,
    },
    series,
  };

  return (
    <div className="embedded-trend-chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}