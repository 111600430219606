import * as React from 'react';
import cx from 'classnames';

import { IUser } from 'modules/user/constants.ts';
import { makeIconInputsForUser } from 'utils/user.ts';

import './style.css';


interface IUserIconProps {
  user: IUser;
  onClick?: any;
  className?: string;
}

const UserIcon = ({
  user,
  className='',
  onClick=() => {},
}: IUserIconProps) => {

  const {
    hex,
    initials,
  } = makeIconInputsForUser(user);

  return (
    <div onClick={onClick} className={cx('carbonara-user-icon', className)} style={{ background: hex }}>
      {initials}
    </div>
  )
}


export default UserIcon;