import { ofType, combineEpics } from 'redux-observable';
import { flatMap, catchError, pluck, map, filter } from 'rxjs/operators';
import { from, empty } from 'rxjs';

import { ActionTypes as UserActionTypes } from 'modules/user/constants.ts';
import { getInstance } from 'api/index.ts';
import { isSystemAdmin } from 'utils/auth.ts';
import { receiveAllCustomers } from 'modules/admin/actions.ts';

const API = getInstance();

const fetchInitialCustomers$ = (action$: any) => action$.pipe(
  ofType(UserActionTypes.RECEIVE_CURRENT_USER),
  pluck('payload'),
  filter(({ user: { role }}: any) => isSystemAdmin(role)),
  flatMap(() =>
    from(API.get('/api/v1/customers'))
      .pipe(
        map(({ data, meta: {pagination} }: any) => receiveAllCustomers(data, pagination)),
        catchError((err: any) => {
          console.warn(err);
          return empty();
        }),
      )
  )
);


export default combineEpics(
  fetchInitialCustomers$,
)